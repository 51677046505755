<mat-expansion-panel expanded>
  <mat-expansion-panel-header>{{ title }}</mat-expansion-panel-header>
  <div class="content">
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>{{ label }}</mat-label>
      <input matInput [formControl]="identifier" />
      <button matSuffix mat-icon-button (click)="identifier.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!identifier.value"
      (click)="zoomTo()"
      i18n="Zoom Position|Button"
    >
      Zoomer sur l'entité
    </button>
  </div>
</mat-expansion-panel>
