import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { Indexable } from '@core/utils/general.utils';
import { ChartComponent } from '@widgets/components/chart/chart.component';
import { ChartConfiguration } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { forkJoin, tap } from 'rxjs';
import { InterraScanService } from '../interra-scan.service';
import { Package } from '../model/package.model';
import { ParcelSynthesis } from '../model/synthesis.model';

@Component({
  standalone: true,
  selector: 'smv-interra-scan-parcel-synthesis',
  templateUrl: './parcel-synthesis.component.html',
  styleUrls: ['./parcel-synthesis.component.scss'],
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatDividerModule, ChartComponent],
})
export class ParcelSynthesisComponent implements OnInit {
  @Input() title = $localize`:InterraScan|Parcel synthesis:Synthèse parcellaire`;
  @Input() appId!: number;

  public synthesis?: ParcelSynthesis;
  public config?: ChartConfiguration;

  private packages: Package[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { appId: number },
    private interraScanService: InterraScanService
  ) {
    this.appId = data.appId;
  }

  ngOnInit(): void {
    const synthObs = this.interraScanService
      .retrieveParcelSynthesis(this.appId)
      .pipe(tap((synthesis) => (this.synthesis = synthesis)));
    const packageObs = this.interraScanService
      .retrieveAllPackages()
      .pipe(tap((packages) => this.packages.push(...packages.sort((a, b) => (a.code > b.code ? 1 : -1)))));
    forkJoin([synthObs, packageObs]).subscribe(this.configurePackageChart.bind(this));
  }

  private configurePackageChart() {
    if (this.packages.length && this.synthesis) {
      this.config = {
        type: 'pie',
        options: this.initOptions(),
        data: {
          labels: this.packages.map((packageOffer) => packageOffer.label),
          datasets: [
            {
              label: $localize`:InterraScan:Packages engagés`,
              data: this.getPackageData(this.synthesis.engagedPackages),
            },
          ],
        },
        plugins: [ChartDataLabels],
      };
    }
  }

  private getPackageData(engagedPackages: Indexable<number>): number[] {
    return this.packages.map((packageOffer) => engagedPackages[packageOffer.code] ?? 0);
  }

  private initOptions() {
    return {
      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: {
          position: 'bottom' as const,
        },
        datalabels: {
          formatter: (value: number) => {
            if (this.synthesis) {
              const total = this.getPackageData(this.synthesis?.engagedPackages).reduce((d1, d2) => d1 + d2);
              const percentage = (value * 100) / total;
              return percentage > 10 ? value + ' (' + percentage.toFixed(0) + '%)' : '';
            }
            return '';
          },
          color: '#fff',
        },
      },
    };
  }
}
