import { Component } from '@angular/core';
import { IcareService } from '../../icare.service';
import { IcareFilter } from '../../model/icare.model';
import { MatExpansionModule } from '@angular/material/expansion';
import { IcareFilterGenerationsComponent } from './icare-filter-generation/icare-filter-generation.component';

@Component({
  standalone: true,
  selector: 'smv-icare-filter-simulations',
  imports: [IcareFilterGenerationsComponent, MatExpansionModule],
  templateUrl: './icare-filter-simulations.component.html',
  styleUrls: ['./icare-filter-simulations.component.scss'],
})
export class IcareFilterSimulationsComponent {
  constructor(private icareService: IcareService) {
    const filter: IcareFilter = {};
    this.icareService.filterState.setParticularValue('dataFilter', filter);
  }
}
