import { Injectable, signal } from '@angular/core';

export interface HeaderImage {
  id: number;
  name: string;
  baseUrl: string;
}

@Injectable({ providedIn: 'root' })
export class NavigationService {
  public readonly headerTitle = signal<string | undefined>(undefined);
  public readonly headerImage = signal<HeaderImage | undefined>(undefined);
}
