import { DecimalPipe, PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statistic',
})
export class StatisticPipe implements PipeTransform {
  constructor(
    private decimalPipe: DecimalPipe,
    private percentPipe: PercentPipe
  ) {}

  transform(value: number, total: number): string {
    const formattedValue = this.decimalPipe.transform(value, '1.0-2');
    const percent = this.percentPipe.transform(value / total, '1.0-1');
    return `${formattedValue} (${percent})`;
  }
}
