import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { LayerGroupConfig } from '@core/model/layer-config.model';
import { DataLayerGroupConfig } from '@feature/client-carto-app/data-layer-config.model';

@Component({
  selector: 'smv-layer-group-editor',
  templateUrl: './layer-group-editor.component.html',
  styleUrls: ['./layer-group-editor.component.scss'],
})
export class LayerGroupEditorComponent implements OnInit {
  @Input() layerGroup?: DataLayerGroupConfig;
  @Output() closePanel = new EventEmitter<DataLayerGroupConfig | undefined>();

  public groupName = new FormControl<string | null>(null, Validators.required);

  ngOnInit() {
    if (this.layerGroup?.config.category) {
      this.groupName.setValue(this.layerGroup.config.category);
    }
  }

  applyChanges(): void {
    const newName = this.groupName.getRawValue();
    if (!newName) {
      throw new Error('Cannot create or update a group without a name');
    }

    let layerGroup = this.layerGroup;
    if (!layerGroup) {
      layerGroup = new DataLayerGroupConfig(new LayerGroupConfig());
    }
    layerGroup.config.category = newName;

    this.closePanel.emit(layerGroup);
  }
}
