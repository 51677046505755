<mat-card class="side-panel">
  <mat-card-title>
    <div>
      <span *ngIf="!widgetConfiguration" i18n="Application panel|Title">Paramétrage de l'application</span>
      <span *ngIf="widgetConfiguration">
        <span i18n="Application panel|Title">Paramétrage de : </span>
        {{ widgetConfiguration.label }}
      </span>
      <button mat-icon-button *ngIf="!widgetConfiguration" (click)="closeSettingsPanel()">
        <mat-icon>keyboard_double_arrow_right</mat-icon>
      </button>
    </div>
  </mat-card-title>
  <mat-card-content>
    <mat-tab-group *ngIf="!widgetConfiguration" [selectedIndex]="1">
      <mat-tab label="Emprise" i18n-label="Application settings|Tab name">
        <smv-application-extent
          [configuration]="configuration"
          [saving]="isSavingWidget"
          (applyGrip)="updateConfiguration($event)"
        />
      </mat-tab>
      <mat-tab label="Fonctionnalités" i18n-label="Application settings|Tab name">
        <smv-widgets-management
          [widgets]="configuration.widgets"
          [saving]="isSavingWidget"
          (editWidgetParameters)="widgetConfiguration = $event"
          (saveWidgets)="updateWidgets($event)"
        />
      </mat-tab>
      <mat-tab label="Spécification" i18n-label="Application settings|Tab name">
        <smv-specification
          [configuration]="configuration"
          [layerGroups]="layerGroups"
          (specConfigUpdate)="updateSpec($event)"
        />
      </mat-tab>
    </mat-tab-group>
    <smv-widget-parameters
      *ngIf="widgetConfiguration"
      [widget]="widgetConfiguration"
      (closeParametersPanel)="widgetConfiguration = undefined"
    />
  </mat-card-content>
</mat-card>
