<mat-expansion-panel expanded>
  <mat-expansion-panel-header>{{ title }}</mat-expansion-panel-header>

  <mat-radio-group [(ngModel)]="filter" (change)="onFilterChange()" class="smv-filter">
    <mat-radio-button value="FILTER_OBSERVATION" i18n>Observations</mat-radio-button>
    @if (authorizedSimulation) {
      <mat-radio-button value="FILTER_SIMULATION" i18n>Simulations</mat-radio-button>
    }
  </mat-radio-group>
</mat-expansion-panel>

@if (filter === 'FILTER_OBSERVATION') {
  <ng-content select="[samples]" />
} @else if (filter === 'FILTER_SIMULATION') {
  <ng-content select="[simulations]" />
}
