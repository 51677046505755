import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { matFormFieldDefaults } from '@core/configuration/material-defaults.config';
import { authInterceptorFn } from '@core/interceptors/auth-interceptor';
import { ClientCartoAppModule } from '@feature/client-carto-app/client-carto-app.module';
import { AnonymousFooterComponent } from '@layout/anonymous-footer/anonymous-footer.component';

import { HeaderComponent } from '@layout/header/header.component';
import { SidemenuComponent } from '@layout/sidemenu/sidemenu.component';

import { apiMessageInterceptorFn } from '@core/interceptors/api-message.interceptor';
import { backendResponseInterceptorFn } from '@core/interceptors/backend-response-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    AnonymousFooterComponent,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HeaderComponent,
    MatSidenavModule,
    MatSnackBarModule,
    SidemenuComponent,
    ClientCartoAppModule,
    MatDialogModule,
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormFieldDefaults },
    provideHttpClient(withInterceptors([apiMessageInterceptorFn, authInterceptorFn, backendResponseInterceptorFn])),
  ],
})
export class AppModule {}
