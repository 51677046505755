<form [formGroup]="paramSpec" class="smv-form">
  <smv-form-field-wrapper formControlName="layer" controlType="select" [selectOptions]="layerOptions">
    <span mat-label i18n="Specifier Settings">Couche à spécifier</span>
  </smv-form-field-wrapper>
  <smv-form-field-wrapper
    *ngIf="paramSpec.controls.layer.value"
    formControlName="attribute"
    controlType="select"
    [selectOptions]="attributes"
    [required]="!!paramSpec.controls.layer.value"
  >
    <span mat-label i18n="Specifier Settings">Attribut discriminant</span>
  </smv-form-field-wrapper>
  <smv-form-field-wrapper *ngIf="paramSpec.controls.layer.value" formControlName="labelPopup">
    <span mat-label i18n="Specifier Settings">Label du discriminant dans la popup</span>
  </smv-form-field-wrapper>
  <smv-form-field-wrapper *ngIf="paramSpec.controls.layer.value" formControlName="labelButtonHeader">
    <span mat-label i18n="Specifier Settings">Label du bouton dans l'en-tête</span>
  </smv-form-field-wrapper>
  <mat-slide-toggle
    labelPosition="before"
    [formControl]="paramSpec.controls.hideLayers"
    class="toggle unselectable"
    i18n="Specifier Settings"
  >
    Dissimuler toutes les autres couches :
  </mat-slide-toggle>
  <mat-slide-toggle
    labelPosition="before"
    [formControl]="paramSpec.controls.restrictToKnown"
    class="toggle unselectable"
    i18n="Specifier Settings"
  >
    Restreindre l'accès aux utilisateurs connus :
  </mat-slide-toggle>
</form>

<div class="smv-sticky-bottom-actions">
  <button mat-stroked-button (click)="resetConfig()">
    <mat-icon>undo</mat-icon><span i18n="Button|Reset">Réinitialiser</span>
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="saveConfig()"
    [disabled]="!paramSpec.controls.layer.value && paramSpec.invalid"
    [ngClass]="{ btnSpinner: saving, loaded: saving }"
  >
    <mat-icon>save</mat-icon><span i18n="Button|save">Enregistrer</span>
  </button>
</div>
