<smv-filter-type-data
  [(filter)]="filter"
  [authorizedSimulation]="authorizedSimulation"
  [setTimelineForSimulation]="setTimelineForSimulation"
  (filterChange)="onFilterChange()"
>
  @if (filter === 'FILTER_OBSERVATION') {
    <smv-vigie-virose-filter-observations [cultures]="cultures" samples />
  } @else if (filter === 'FILTER_SIMULATION') {
    <smv-vigie-virose-filter-simulation [cultures]="cultures" simulations />
  }
</smv-filter-type-data>
