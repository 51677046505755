<div class="timeline-container">
  <div class="timeline">
    <div class="date-row">
      <div class="timeline-dates">
        <div class="track">
          <div
            *ngFor="let date of dates"
            (click)="clickOnDate(date)"
            [matTooltip]="date | timelineDate: periodicity"
            matTooltipClass="small-tooltip"
            i18n-matTooltip
            class="timeline-date"
            [class]="{ 'date-selected': currentDate === date, graduation: graduationVisible }"
          ></div>
        </div>
        <div *ngIf="currentDate" class="date">
          {{ currentDate | timelineDate: periodicity }}
        </div>
      </div>
      <div
        class="unfold-button"
        matTooltip="Afficher ou masquer les commandes de lecture"
        i18n-matTooltip="Timeline"
        (click)="toggleControls()"
      >
        <mat-icon *ngIf="!controlsVisible">unfold_more</mat-icon>
        <mat-icon *ngIf="controlsVisible">unfold_less</mat-icon>
      </div>
    </div>
    <div class="actions" [class]="{ hidden: !controlsVisible }">
      <button mat-icon-button [disabled]="isFirstDate" (click)="previous()">
        <mat-icon>skip_previous</mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="playState === PlayState.INITIAL || playState === PlayState.PAUSING"
        (click)="play()"
      >
        <mat-icon>play_arrow</mat-icon>
      </button>
      <button mat-icon-button *ngIf="playState === PlayState.PLAYING" (click)="pause()">
        <mat-icon>pause</mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="playState === PlayState.PLAYING || playState === PlayState.PAUSING"
        (click)="stop()"
      >
        <mat-icon>stop</mat-icon>
      </button>
      <button mat-icon-button [disabled]="isLastDate" (click)="next()">
        <mat-icon>skip_next</mat-icon>
      </button>

      <button mat-icon-button (click)="settingsOpen = !settingsOpen" [class]="{ 'mat-primary': settingsOpen }">
        <mat-icon>video_settings</mat-icon>
      </button>
    </div>
    <div class="player-settings" *ngIf="settingsOpen">
      <button mat-button class="speed">
        <span (click)="changeSpeed()" class="speed unselectable">
          <ng-container i18n="Timeline">Vitesse</ng-container> x{{ speeds[speedIndex] }}
        </span>
      </button>

      <mat-slide-toggle [checked]="graduationVisible" (change)="toggleGraduation($event.checked)" i18n="Timeline">
        Afficher les graduations
      </mat-slide-toggle>
    </div>
  </div>
</div>
