import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Collection, Feature, Map, MapBrowserEvent, Overlay } from 'ol';
import { click } from 'ol/events/condition';
import BaseEvent from 'ol/events/Event';
import { FeatureLike } from 'ol/Feature';
import GeoJSONFormat, { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import { Select } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Circle, Stroke, Style } from 'ol/style';

import { LayerType } from '@core/model/application-api/layer.model';
import { ContextItem } from '@core/model/context-item.model';
import { GeoserverLayersUtils } from '@core/utils/geoserver-layers.utils';
import { MapUtils } from '@core/utils/map.utils';
import { DataLayerConfig } from '@feature/client-carto-app/data-layer-config.model';
import { WidgetTrap } from '@widgets/components/statistic/statistic.model';
import { StatisticService } from '@widgets/components/statistic/statistic.service';
import Point from 'ol/geom/Point';
import { debounceTime, forkJoin, fromEvent, map, of } from 'rxjs';
import { ApplicationApiService } from './api/application-api.service';

export interface Selection {
  features: Feature[];
  layer: DataLayerConfig;
}

interface ResultCall {
  geoCollection: GeoJSONFeatureCollection;
  layer: DataLayerConfig;
}

type EventType = 'click' | 'hover';

@Injectable({ providedIn: 'root' })
export class SelectionService {
  public selection = new Select({});
  public selectedFeatures = new ContextItem<Selection[] | null>(null);

  private map?: Map;

  private popupOverlay?: Overlay;
  private overlayContent?: HTMLElement;

  private selectionWidgetStatistic = new Select({
    condition: click,
    multi: true,
  });

  private hoverSelectionSource = new VectorSource({});
  private hoverSelection?: VectorLayer<VectorSource>;

  private hasVectorTipOpen = false;

  constructor(
    private http: HttpClient,
    private applicationService: ApplicationApiService,
    private statisticService: StatisticService
  ) {}

  get selectableLayers(): DataLayerConfig[] {
    if (!this.map) {
      return [];
    }

    const mapScale = MapUtils.getMapScale(this.map);
    return this.applicationService.applicationLayers.filter((layer) => {
      return !layer.config.baseLayer && layer.config.type !== LayerType.WMTS && layer.isDisplayed(mapScale);
    });
  }

  initSelectionService(mapInstance: Map) {
    this.map = mapInstance;
    this.addHoverSelection();
    this.addClickSelections();
  }

  addHoverSelection() {
    this.overlayContent = document.createElement('div');
    this.overlayContent.className = 'ol-feature-overlay unselectable';
    this.popupOverlay = new Overlay({
      element: this.overlayContent,
      offset: [0, -15],
      positioning: 'bottom-center',
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
    this.map?.addOverlay(this.popupOverlay);

    this.initHoverHighlightLayer();
    if (this.map) {
      fromEvent<MapBrowserEvent<MouseEvent>>(this.map, 'pointermove')
        .pipe(debounceTime(100))
        .subscribe((event: MapBrowserEvent<MouseEvent>) => this.selectFeatures(event, 'hover'));
    }
  }

  initHoverHighlightLayer() {
    const strokeStyle = new Stroke({
      color: 'rgb(0,255,0)',
      width: 2,
    });
    this.hoverSelection = new VectorLayer({
      properties: {
        title: 'Hover Selection Layer',
      },
      map: this.map,
      source: this.hoverSelectionSource,
      style: [
        new Style({
          image: new Circle({
            stroke: strokeStyle,
            radius: 10,
          }),
          stroke: strokeStyle,
        }),
      ],
      zIndex: 300,
    });
  }

  addClickSelections() {
    this.selectedFeatures.reset();
    this.selectionWidgetStatistic.getFeatures().clear();

    this.selectionWidgetStatistic.on('select', this.selectWidgetTraps.bind(this));
    this.map?.addInteraction(this.selectionWidgetStatistic);

    this.map?.on('click', this.selectFeatures.bind(this));
    this.map?.addInteraction(this.selection);
  }

  addVectorFeatureToSelection(feature: Feature, layer: DataLayerConfig) {
    this.selection.getFeatures().clear();
    this.selection.getFeatures().push(feature);
    const selection: Selection = {
      features: [feature],
      layer: layer,
    };
    this.selectedFeatures.setValue([selection]);
  }

  activeWidgetStatisticSelection(active: boolean) {
    this.selectionWidgetStatistic.setActive(active);
  }

  private computeSelectionWidgetStatistic(features: Feature[]) {
    const traps: WidgetTrap[] = [];
    features.forEach((feature) => {
      const trap = feature.getProperties() as WidgetTrap;
      if (trap.trap_code && !traps.find((selectedTrap) => selectedTrap.trap_code == trap.trap_code)) {
        traps.push(trap);
      }
    });
    if (traps.length) {
      this.statisticService.statisticState.setValue({
        display: true,
        traps: traps,
      });
    }
  }

  private selectWidgetTraps(event: BaseEvent | Event) {
    const features: Collection<Feature> = event.target.getFeatures();
    this.computeSelectionWidgetStatistic(features.getArray());
  }

  private selectFeaturesVector(event: MapBrowserEvent<MouseEvent>, layer: DataLayerConfig, eventType: EventType) {
    const selection: Selection = {
      features: [],
      layer: layer,
    };

    const features: FeatureLike[] =
      this.map?.getFeaturesAtPixel(event.pixel, {
        layerFilter: (l) => {
          return l == layer.olLayer;
        },
      }) ?? [];
    selection.features.push(...(features as Feature[]));

    if (selection.features.length || this.hasVectorTipOpen) {
      this.updateSelections([selection], event, eventType);
      this.hasVectorTipOpen = selection.features.length > 0;
    } else {
      this.hasVectorTipOpen = false;
    }

    return selection.features.length;
  }

  private selectFeatures(event: MapBrowserEvent<MouseEvent>, eventType: EventType = 'click') {
    if (this.map) {
      const calls = [];
      for (const layer of this.selectableLayers) {
        if (this.selectFeaturesVector(event, layer, eventType)) {
          return;
        }
        let url;
        if (layer.config.type == LayerType.WMS) {
          url = GeoserverLayersUtils.wmsGetFeatureInfoUrl(event, layer, this.map);
        } else if (layer.config.type == LayerType.WMTS) {
          url = GeoserverLayersUtils.wmtsGetFeatureInfoUrl(event, layer, this.map);
        }
        if (url) {
          let headersObject = new HttpHeaders({});
          if (layer.config.authenticationInfos?.login?.length && layer.config.authenticationInfos?.password?.length) {
            headersObject = headersObject.append(
              'Login',
              'Basic ' + layer.config.authenticationInfos.login + ':' + layer.config.authenticationInfos.password
            );
          }
          calls.push(
            this.http.get<GeoJSONFeatureCollection>(url, { headers: headersObject }).pipe(
              map((result: GeoJSONFeatureCollection): ResultCall => {
                return {
                  geoCollection: result,
                  layer: layer,
                };
              })
            )
          );
        }
      }

      forkJoin(calls).subscribe({
        next: (results: ResultCall[]) => {
          const allFeatures: Feature[] = [];
          const selections: Selection[] = [];
          for (const result of results) {
            const features = new GeoJSONFormat().readFeatures(result.geoCollection);
            const layerSelection = selections.find((selection) => selection.layer == result.layer);
            if (layerSelection) {
              layerSelection.features.push(...features);
            } else {
              selections.push({ features: features, layer: result.layer });
            }
            allFeatures.push(...features);
          }
          this.updateSelections(selections, event, eventType, allFeatures);
        },
        error: (error) => {
          return of({ isError: true, error: error });
        },
      });
    }
  }

  private updateSelections(
    selection: Selection[],
    event: MapBrowserEvent<MouseEvent>,
    eventType: EventType,
    allFeatures?: Feature[]
  ) {
    if (eventType === 'click') {
      if (this.selection.getActive()) {
        this.selectedFeatures.setValue(selection);
      }
      if (this.selectionWidgetStatistic.getActive() && allFeatures) {
        this.computeSelectionWidgetStatistic(allFeatures);
      }
    } else if (eventType === 'hover') {
      if (selection[0].layer.config.featureOverlay.active && selection[0].features.length) {
        if (this.setInformationOverlay(selection[0], event)) {
          this.hoverSelectionSource.clear();
          this.hoverSelectionSource.addFeature(selection[0].features[0]);
        }
      } else {
        if (this.overlayContent) {
          this.overlayContent.innerHTML = '';
        }
        this.hoverSelectionSource.clear();
        this.popupOverlay?.setPosition(undefined);
      }
    }
  }

  private setInformationOverlay(selection: Selection, event: MapBrowserEvent<MouseEvent>) {
    if (this.overlayContent) {
      const feature = selection.features[0];
      const attributes = new RegExp(/{(.*?)}/, 'ig');
      const content =
        selection.layer.config.featureOverlay.content?.replace(attributes, (a, b) => {
          const value = feature ? feature.getProperties()[b] : null;
          return value != undefined ? value : '';
        }) ?? '';
      let position = event.coordinate;
      if (feature.getGeometry()?.getType() === 'Point') {
        const point = <Point | undefined>feature.getGeometry();
        position = point?.getCoordinates() ?? event.coordinate;
      }
      if (this.overlayContent.innerHTML === content && this.popupOverlay?.getPosition() === position) {
        return false;
      }
      this.overlayContent.innerHTML = content;
      this.popupOverlay?.setPosition(position);
    }
    return true;
  }
}
