import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DragDropItem } from '@components/drag-drop-item/drag-drop-item.model';
import { DataLayerConfig, DataLayerGroupConfig } from '@feature/client-carto-app/data-layer-config.model';

interface FlattenedLayerGroup {
  path: string[];
  group: DataLayerGroupConfig;
}

@Component({
  selector: 'smv-layer-sort',
  templateUrl: './layer-sort.component.html',
  styleUrls: ['./layer-sort.component.scss'],
})
export class LayerSortComponent implements OnInit {
  @Input() layerGroups: DataLayerGroupConfig[] = [];
  @Output() closePanel = new EventEmitter<DataLayerGroupConfig[] | undefined>();

  public flatLayerGroups: FlattenedLayerGroup[] = [];
  public isLayerSort = true;
  public rootItem = DragDropItem.root<DataLayerGroupConfig>();
  public unsavedSortChanges: DataLayerGroupConfig[] = [];
  public helpVisible = false;

  ngOnInit(): void {
    this.layerGroups.forEach((group) => {
      const unsavedGroup = group.clone();
      this.unsavedSortChanges.push(unsavedGroup);
      this.rootItem.children.push(this.createDragDropItem(unsavedGroup));
    });
    this.flattenUnsavedGroups();
  }

  toggleSortMode(): void {
    this.isLayerSort = !this.isLayerSort;

    if (this.isLayerSort) {
      this.flattenUnsavedGroups();
    }
  }

  layerReordered(event: CdkDragDrop<DataLayerConfig[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  applyChanges(): void {
    if (!this.isLayerSort) {
      this.updateUnsavedChanges();
    }
    this.closePanel.emit(this.unsavedSortChanges);
  }

  private updateUnsavedChanges(): void {
    this.unsavedSortChanges = this.rootItem.children.map((child) => this.rebuildLayerGroupTreeItem(child));
  }

  private flattenUnsavedGroups(): void {
    this.updateUnsavedChanges();
    this.flatLayerGroups = [];
    this.unsavedSortChanges.forEach((group) => {
      this.flatLayerGroups.push(...this.flattenLayerGroup(group, []));
    });
  }

  private flattenLayerGroup(group: DataLayerGroupConfig, parent: string[]): FlattenedLayerGroup[] {
    const currentGroup: FlattenedLayerGroup = {
      path: [...parent, group.config.category ?? ''],
      group,
    };
    const flattened: FlattenedLayerGroup[] = [];
    group.subgroups.forEach((subgroup) => {
      flattened.push(...this.flattenLayerGroup(subgroup, currentGroup.path));
    });
    flattened.unshift(currentGroup);
    return flattened;
  }

  private createDragDropItem(group: DataLayerGroupConfig): DragDropItem<DataLayerGroupConfig> {
    const dragDropData = group.clone();
    dragDropData.subgroups = [];
    const item = new DragDropItem<DataLayerGroupConfig>(group.config.category ?? 'group', dragDropData);
    item.children = group.subgroups.map((subgroup) => this.createDragDropItem(subgroup));
    return item;
  }

  private rebuildLayerGroupTreeItem(dragDropItem: DragDropItem<DataLayerGroupConfig>): DataLayerGroupConfig {
    const item = dragDropItem.getData();
    item.subgroups = dragDropItem.children.map((child) => this.rebuildLayerGroupTreeItem(child));
    return item;
  }
}
