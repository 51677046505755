import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { ApiMessage } from '@core/model/api-message.model';
import { map, Observable } from 'rxjs';

function isApiMessageError(object: object | null): object is ApiMessage {
  return object ? 'errorCode' in object : false;
}

export const apiMessageInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  return next(req).pipe(
    map((event: HttpEvent<unknown>) => {
      if (event instanceof HttpResponse && typeof event.body === 'object' && isApiMessageError(event.body)) {
        throw new Error(event.body.errorCode, { cause: event.body.errorMessage ?? event.body.trace });
      }
      return event;
    })
  );
};
