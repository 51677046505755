import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, computed, EventEmitter, Input, Output, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { DialogService } from '@components/dialog/dialog.service';
import { Application } from '@core/model/application.model';
import { ApplicationApiService, SpecificationState } from '@core/services/api/application-api.service';
import { NavigationService } from '@core/services/navigation.service';
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'smv-header',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() isLoggedIn = false;
  @Output() toggleMenu = new EventEmitter<void>();

  public headerTitle = this.navigation.headerTitle;
  public headerImage = this.navigation.headerImage;

  public baseUrl?: string;
  public settingsPanelState: Signal<boolean | undefined>;
  public application: Signal<Application | undefined>;
  public specificationState: Signal<SpecificationState | undefined>;
  public specificationConfig = computed(() => this.specificationState()?.config);

  constructor(
    private navigation: NavigationService,
    private applicationService: ApplicationApiService,
    private dialogService: DialogService,
    private notificationService: NotificationService
  ) {
    this.baseUrl = this.applicationService.getBaseUrl();

    this.settingsPanelState = toSignal(this.applicationService.settingsPanelState.getStream());
    this.application = toSignal(this.applicationService.currentApplication.getStream());
    this.specificationState = toSignal(this.applicationService.isSpecification.getStream());
  }

  toggleSettingsPanel() {
    this.applicationService.settingsPanelState.setValue(!this.settingsPanelState());
  }

  getHelpPdf() {
    const appId = this.application()?.id;
    if (appId) {
      this.applicationService.getHelpPdf(appId).subscribe({
        next: (blob) => {
          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL, 'u_blank');
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === HttpStatusCode.PreconditionFailed) {
            this.notificationService.error(
              $localize`:Notification|Error:Le pdf n'est pas accessible pour cette application. Si ceci vous semble être une erreur, veuillez contacter un administrateur.`
            );
          }
          this.notificationService.unknownServerError();
        },
      });
    }
  }

  openIframeDialog() {
    const appTutorial = this.application()?.tutorialUrl;
    if (appTutorial) {
      this.dialogService.openIframe({
        title: $localize`:Application Tuto|Title:Tutoriel de l'application`,
        contentUrl: appTutorial,
      });
    }
  }

  changeSpecificationEntity() {
    this.applicationService.isSpecification.setParticularValue('opened', true);
  }
}
