import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Loader } from '@components/loading/loading.component';
import { DrawInteractionHelper } from '@core/helpers/draw-interaction.helper';
import { QualiH2OFilter, RpgStyle } from '@core/model/widgets/qualih2o.model';
import { NotificationService } from '@core/services/notification.service';
import { MapUtils } from '@core/utils/map.utils';
import { Qualih2oService } from '@feature/client-carto-app/widgets/quali-h2o/qualih2o.service';
import { Qualih2oExporterService } from './quali-h2o-exporter.service';
import { aacFilters, cropFilters, vulnerabilityFilters } from './quali-h2o-panel-config';

const MAX_SCALE_KML = 500000;

@Component({
  selector: 'smv-quali-h2o',
  templateUrl: './quali-h2o.component.html',
  styleUrls: ['./quali-h2o.component.scss', '../../client-carto-app-panel.scss'],
})
export class QualiH2oComponent {
  @Input() panelHidden = true;
  @Output() closePanel = new EventEmitter<void>();

  public readonly activeAacFilter$ = this.qualih2oService.rpgLayer.activeAac$.asObservable();
  public readonly rpgLayer$ = this.qualih2oService.rpgLayer.layerConfig$.asObservable();
  public readonly aacLayer$ = this.qualih2oService.aacLayer.layerConfig$.asObservable();
  public readonly cropOptions: QualiH2OFilter[] = cropFilters;
  public readonly vulnerabilityOptions: QualiH2OFilter[] = vulnerabilityFilters;
  public readonly aacFilterOptions: QualiH2OFilter[] = aacFilters;

  public activeRpgStyle: RpgStyle = 'NONE';
  public cropSelection = this.cropOptions.map((option) => option.filterValue);
  public vulnerabilitySelection = this.vulnerabilityOptions.map((option) => option.filterValue);
  public aacFilterSelection = this.aacFilterOptions.map((option) => option.filterValue);
  public areaDrawingInteraction?: DrawInteractionHelper;
  public pdfLoader: Loader = {
    text: $localize`:PDF loader:Préparation du PDF, veuillez patienter`,
    condition: false,
  };

  constructor(
    private qualih2oService: Qualih2oService,
    private qualih2oExporterService: Qualih2oExporterService,
    private notification: NotificationService
  ) {}

  changeRpgStyle(newStyle: RpgStyle): void {
    this.activeRpgStyle = newStyle;
    this.qualih2oService.rpgLayer.changeStyle(newStyle);
  }

  rpgFilterChanged(): void {
    const cropFilter = this.toCqlFilter('groupe_culture', this.cropSelection);
    const vulnerabilityFilter = this.toCqlFilter('vulnerabilite', this.vulnerabilitySelection);
    this.qualih2oService.rpgLayer.addFilter('vulnerability', vulnerabilityFilter);
    this.qualih2oService.rpgLayer.addFilter('crop', cropFilter);
  }

  aacFilterChanged(): void {
    this.qualih2oService.aacLayer.addFilter('type', this.toCqlFilter('type_capt', this.aacFilterSelection));
  }

  drawAreaForStats(): void {
    this.areaDrawingInteraction = this.qualih2oService.drawParcelArea();
  }

  cancelAreaDrawing(): void {
    this.areaDrawingInteraction?.removeFromMap();
    this.areaDrawingInteraction = undefined;
  }

  removeAacFilter(): void {
    this.qualih2oService.rpgLayer.removeAacFilter();
  }

  downloadKML(): void {
    const scale = MapUtils.getMapScale(this.qualih2oService.mapInstance);
    if (scale > MAX_SCALE_KML) {
      this.notification.error($localize`L'export KML n'est possible qu'à partir de l'échelle` + ' 1/' + MAX_SCALE_KML);
    } else {
      this.qualih2oExporterService.exportRpgToKML();
    }
  }

  downloadPdf(): void {
    this.pdfLoader.condition = true;
    this.qualih2oExporterService.exportToPdf().finally(() => (this.pdfLoader.condition = false));
  }

  private toCqlFilter(property: string, values: string[]): string | undefined {
    return values.length ? `${property} IN (${values.map((value) => `'${value}'`).join(',')})` : undefined;
  }
}
