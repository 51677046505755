<mat-expansion-panel expanded>
  <mat-expansion-panel-header i18n="OptiSemis|Filter">Conseil</mat-expansion-panel-header>
  <div class="smv-flex-col">
    @for (recommendation of recommendations; track recommendation.label) {
      <mat-checkbox color="accent" [(ngModel)]="recommendation.selected" (change)="computeFilter()">
        {{ recommendation.label }}
      </mat-checkbox>
    }
  </div>
</mat-expansion-panel>
