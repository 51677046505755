import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { LayerSubtype, LayerType } from '@core/model/application-api/layer.model';
import { WidgetType } from '@core/model/application-api/widget.model';
import { MapService } from '@core/services/map.service';
import { DataLayerConfig } from '../data-layer-config.model';
import { WidgetService } from '../widgets/widgets.service';
import { VectorLegendComponent } from './vector-legend/vector-legend.component';
import { WmsLegendComponent } from './wms-legend/wms-legend.component';

@Component({
  selector: 'smv-legend',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, VectorLegendComponent, WmsLegendComponent],
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
})
export class LegendComponent {
  public layers: DataLayerConfig[] = [];
  public layersWithLegend: DataLayerConfig[] = [];
  public LayerType = LayerType;

  constructor(
    private mapService: MapService,
    private widgetService: WidgetService
  ) {
    this.layers = this.mapService.getAllVisibleLayers();
    this.layersWithLegend = this.layers.filter(
      (layer) => layer.config.isLegendDisplayed && layer.config.subtype !== LayerSubtype.HEATMAP
    );
    if (this.widgetService.getWidgetContextItem(WidgetType.VIGIEVIROSE).getValue()?.visible) {
      this.filterVigieViroseLayers();
    }
  }

  filterVigieViroseLayers() {
    const found_aphid_virus = this.layersWithLegend.find((layer) => layer.config.code === 'trap_aphid_pav_mav_rpv');
    const found_leafhopper_virus = this.layersWithLegend.find((layer) => layer.config.code === 'trap_leafhopper_wdv');
    const found_aphid_rapeseed_virus = this.layersWithLegend.find(
      (layer) => layer.config.code === 'trap_aphid_bwyv_tumv_camv'
    );

    if (found_aphid_virus) {
      this.layersWithLegend = this.layersWithLegend.filter((layer) => layer.config.code !== 'trap_aphid_cereals');
    }
    if (found_leafhopper_virus) {
      this.layersWithLegend = this.layersWithLegend.filter((layer) => layer.config.code !== 'trap_leafhopper_cereals');
    }
    if (found_aphid_rapeseed_virus) {
      this.layersWithLegend = this.layersWithLegend.filter((layer) => layer.config.code !== 'trap_aphid_rapeseed');
    }
  }
}
