@if (isLoggedIn) {
  <div class="header-container">
    <div class="side-menu-toggle">
      <button mat-icon-button (click)="toggleMenu.emit()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <div class="app-title">
      @let image = headerImage();
      @if (image) {
        <img src="{{ image.baseUrl }}/{{ image.id }}/logo" [alt]="image.name" height="40px" />
      }
      @let title = headerTitle();
      @if (title) {
        <span>{{ title }}</span>
      }
    </div>
    @if (specificationState() && specificationConfig()) {
      <div>
        <button mat-stroked-button (click)="changeSpecificationEntity()" [disabled]="specificationState()!.opened">
          {{ specificationConfig()!.labelButtonHeader }}
        </button>
      </div>
    }
    @if (application()?.displayedHelpPdf) {
      <div>
        <button mat-icon-button (click)="getHelpPdf()"><mat-icon>help_center</mat-icon></button>
      </div>
    }
    @if (application()?.displayedTutorial) {
      <div>
        <button mat-icon-button (click)="openIframeDialog()">
          <mat-icon>play_arrow</mat-icon>
        </button>
      </div>
    }
    @if (settingsPanelState() !== undefined) {
      <div>
        <button mat-icon-button (click)="toggleSettingsPanel()"><mat-icon>settings</mat-icon></button>
      </div>
    }
  </div>
} @else {
  <div class="header-container mobile-stacked">
    <div class="app-title">MAPVIEW™</div>
    <div class="actions">
      <button mat-button color="primary" routerLink="/inscription" i18n="Header">S'inscrire</button>
      <button mat-button color="primary" routerLink="/connexion" i18n="Header">Se connecter</button>
    </div>
  </div>
}
