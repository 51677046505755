import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { StatisticService } from '@widgets/components/statistic/statistic.service';

@Component({
  selector: 'smv-sheet-statistic',
  standalone: true,
  imports: [NgIf, MatCardModule, MatButtonModule, MatIconModule, MatDividerModule, MatExpansionModule],
  templateUrl: './sheet-statistic.component.html',
  styleUrls: ['./sheet-statistic.component.scss'],
})
export class SheetStatisticComponent implements OnInit, OnDestroy {
  @Input() widgetLabel = '';
  @Input() loading = true;
  @Input() isDataLoaded = false;

  @Input() titleMulti = '';
  public isMulti = false;

  constructor(private statisticService: StatisticService) {}

  ngOnInit(): void {
    const statisticConfig = this.statisticService.statisticState.getValue();
    if (statisticConfig.filter) {
      this.isMulti = true;
    }
  }

  ngOnDestroy() {
    this.statisticService.statisticState.reset();
  }
}
