export interface FeatureOption {
  id: string;
  name: string;
}

export class ApplicationDefinition {
  public static readonly appQualiH2O: FeatureOption = { id: 'QUALIH2O', name: "Quali'H₂O" };
  public static readonly appEudemis: FeatureOption = { id: 'EUDEMIS', name: 'Eudémis' };
  public static readonly appEudemisAdmin: FeatureOption = { id: 'EUDEMIS_ADMIN', name: 'Eudémis Administration' };
  public static readonly appVigieVirose: FeatureOption = { id: 'VIGIEVIROSE', name: 'VigieVirose' };
  public static readonly appVigieViroseAdmin: FeatureOption = {
    id: 'VIGIEVIROSEADMIN',
    name: 'VigieVirose Administration',
  };
  public static readonly appVisiovol: FeatureOption = { id: 'VISIOVOL', name: 'Visiovol' };
  public static readonly appCNC: FeatureOption = { id: 'QUALICIBLE_CNC', name: "Quali'Cible CNC" };
  public static readonly appOptiSemis: FeatureOption = { id: 'OPTI_SEMIS', name: 'OptiSemis' };
  public static readonly appInterraScan: FeatureOption = { id: 'INTERRA_SCAN', name: 'InterraScan' };
  public static readonly appRevolt: FeatureOption = { id: 'REVOLT', name: 'Rêvolt' };
  public static readonly appDefault: FeatureOption = { id: 'DEFAULT', name: ' Par défaut' };
  public static readonly appChampPropre: FeatureOption = { id: 'CHAMP_PROPRE', name: 'Champ Propre' };

  public static readonly appArray: FeatureOption[] = [
    ApplicationDefinition.appDefault,
    ApplicationDefinition.appQualiH2O,
    ApplicationDefinition.appEudemis,
    ApplicationDefinition.appEudemisAdmin,
    ApplicationDefinition.appVigieVirose,
    ApplicationDefinition.appVigieViroseAdmin,
    ApplicationDefinition.appVisiovol,
    ApplicationDefinition.appCNC,
    ApplicationDefinition.appOptiSemis,
    ApplicationDefinition.appInterraScan,
    ApplicationDefinition.appRevolt,
    ApplicationDefinition.appChampPropre,
  ];
}
