import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationConfig } from '@core/model/application.model';
import { MapService } from '@core/services/map.service';
import { cloneDeep } from 'lodash-es';

interface GripFormGroup {
  longitude: FormControl<number | null>;
  latitude: FormControl<number | null>;
  zoom: FormControl<number | null>;
}

@Component({
  selector: 'smv-application-extent',
  templateUrl: './extent.component.html',
  styleUrls: ['./extent.component.scss'],
})
export class ExtentComponent implements OnInit {
  @Input() configuration!: ApplicationConfig;
  @Input() saving = false;

  @Output() applyGrip = new EventEmitter<ApplicationConfig>();

  private configurationState!: ApplicationConfig;
  public gripForm = new FormGroup<GripFormGroup>({
    longitude: new FormControl<number>(0, [Validators.required, Validators.min(-180), Validators.max(180)]),
    latitude: new FormControl<number>(0, [Validators.required, Validators.min(-180), Validators.max(180)]),
    zoom: new FormControl<number>(6, [Validators.required, Validators.min(3), Validators.max(28)]),
  });

  constructor(private mapService: MapService) {}

  ngOnInit() {
    this.configurationState = cloneDeep(this.configuration);
    this.patchForm();
  }

  private patchForm() {
    const patchValues = {
      longitude: this.configurationState.initLocation[0],
      latitude: this.configurationState.initLocation[1],
      zoom: this.configurationState.initZoom,
    };
    this.gripForm.patchValue(patchValues);
  }

  applyMapBounds() {
    const { longitude, latitude, zoom } = this.gripForm.value;
    if (longitude && latitude && zoom) {
      this.configurationState.initLocation[0] = longitude;
      this.configurationState.initLocation[1] = latitude;
      this.configurationState.initZoom = zoom;
    }
    this.applyGrip.emit(this.configurationState);
  }

  captureCurrentMapBounds() {
    const currentZoom = this.mapService.getCurrentZoom();
    const coordinates = this.mapService.getCurrentCoordinates();
    if (currentZoom) {
      this.configurationState.initZoom = currentZoom;
    }
    if (coordinates) {
      this.configurationState.initLocation[0] = coordinates[0];
      this.configurationState.initLocation[1] = coordinates[1];
    }
    this.patchForm();
  }
}
