import { Component, EventEmitter, Input, model, Output } from '@angular/core';
import { Application, ApplicationConfig } from '@core/model/application.model';
import { LayerConfig } from '@core/model/layer-config.model';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { MapService } from '@core/services/map.service';
import { NotificationService } from '@core/services/notification.service';
import { rebuildZIndexes } from '@core/utils/app-config-utils';
import { DataLayerConfig, DataLayerGroupConfig } from '@feature/client-carto-app/data-layer-config.model';
import { finalize } from 'rxjs';

enum EditionPanel {
  SORT = 'sort',
  ADD_LAYER_GROUP = 'addLayerGroup',
  ADD_EXTERNAL = 'addExternalLayer',
  MODIFY_PROPERTIES = 'modifyLayerProperties',
}

@Component({
  selector: 'smv-data-layer-edition-panel',
  templateUrl: './data-layer-edition-panel.component.html',
  styleUrls: ['./data-layer-edition-panel.component.scss', '../../client-carto-app-panel.scss'],
})
export class DataLayerEditionPanelComponent {
  application = model.required<Application>();

  @Input() layerGroups: DataLayerGroupConfig[] = [];
  @Output() layersUpdate = new EventEmitter<DataLayerGroupConfig[]>();
  @Output() closePanel = new EventEmitter<void>();

  public panelTitle?: string;
  public activePanel?: EditionPanel;
  public editedLayerGroup?: DataLayerGroupConfig;
  public editedLayer?: DataLayerConfig;
  public EditionPanel = EditionPanel;

  public isCreating = false;

  constructor(
    private applicationApi: ApplicationApiService,
    private mapService: MapService,
    private notificationService: NotificationService
  ) {}

  cancelUpdates(): void {
    this.activePanel = undefined;
    this.panelTitle = undefined;
  }

  sortLayers(): void {
    this.activePanel = EditionPanel.SORT;
    this.panelTitle = $localize`:Application panel|Title:Réordonner les couches`;
  }

  addExternalLayer(): void {
    this.activePanel = EditionPanel.ADD_EXTERNAL;
    this.panelTitle = $localize`:Application panel|Title:Créer une couche à partir d'une source externe`;
  }

  openLayerGroupEditor(updatedGroup?: DataLayerGroupConfig): void {
    this.activePanel = EditionPanel.ADD_LAYER_GROUP;
    if (updatedGroup) {
      this.panelTitle = $localize`:Application panel|Title:Renommer le groupe`;
      this.editedLayerGroup = updatedGroup;
    } else {
      this.panelTitle = $localize`:Application panel|Title:Créer un groupe`;
    }
  }

  addOrUpdateGroup(layerGroup?: DataLayerGroupConfig): void {
    if (layerGroup) {
      if (!this.editedLayerGroup) {
        this.layerGroups.push(layerGroup);
      }
      this.updateLayers(this.layerGroups);
    } else {
      this.activePanel = undefined;
    }
  }

  openLayerProperties(layer: DataLayerConfig): void {
    this.activePanel = EditionPanel.MODIFY_PROPERTIES;
    this.panelTitle = $localize`:Application panel|Title:Propriétés de la couche`;
    this.editedLayer = layer;
  }

  createLayer(layer: LayerConfig): void {
    const config = new DataLayerConfig(layer);
    const ungrouped = this.layerGroups.find((group) => group.config.isUngrouped);
    if (ungrouped) {
      ungrouped.layers.unshift(config);
      this.updateLayers(this.layerGroups);
    }
  }

  saveLayer(layer: LayerConfig): void {
    if (this.editedLayer) {
      layer.generatedId = this.editedLayer.generatedId;
      this.editedLayer.config = layer;
      this.updateLayers(this.layerGroups, true);
    }
  }

  updateLayers(updatedLayers?: DataLayerGroupConfig[], updateStyle = false): void {
    if (updatedLayers) {
      rebuildZIndexes(updatedLayers);
      const apiLayers = updatedLayers.flatMap((group) => {
        group.updateConfigLayers();
        return group.config.toApiLayerGroup();
      });
      const updatedConfiguration = new ApplicationConfig(this.application().config);
      updatedConfiguration.layers = apiLayers;
      this.applicationApi
        .updateApplicationConfig(this.application(), updatedConfiguration)
        .pipe(finalize(() => (this.isCreating = false)))
        .subscribe({
          next: (app) => {
            this.application.set(app);
            if (updateStyle && this.editedLayer) {
              this.mapService.updateStyle(this.editedLayer);
            }
            this.activePanel = undefined;
            this.editedLayer = undefined;
            this.layersUpdate.emit(updatedLayers);
            this.notificationService.success(
              $localize`:Layer Configuration:La configuration des couches a été enregistrée avec succès.`
            );
          },
          error: () => {
            this.notificationService.unknownServerError();
          },
        });
    } else {
      this.activePanel = undefined;
    }
  }
}
