import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Position } from '@core/model/position.model';
import { MapService } from '@core/services/map.service';
import { NotificationService } from '@core/services/notification.service';
import { PanelComponent } from '../panel/panel.component';

import { fromLonLat } from 'ol/proj';
import { Observable } from 'rxjs';
import { MatExpansionModule } from '@angular/material/expansion';

const modules = [
  CommonModule,
  MatFormFieldModule,
  ReactiveFormsModule,
  MatExpansionModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  PanelComponent,
];

@Component({
  selector: 'smv-zoom-position',
  standalone: true,
  imports: modules,
  templateUrl: './zoom-position.component.html',
  styleUrls: ['./zoom-position.component.scss'],
})
export class ZoomPositionComponent {
  @Input() positionFunction!: (value: string) => Observable<Position | null>;
  @Input() label = $localize`:Zoom Position|Label:Identifiant`;

  public title = $localize`:Zoom Position|Title:Sélection`;
  public identifier = new FormControl<string>('', { nonNullable: true });

  constructor(
    private mapService: MapService,
    private notificationService: NotificationService
  ) {}

  zoomTo() {
    this.positionFunction(this.identifier.value).subscribe({
      next: (position) => {
        if (position?.geom) {
          this.mapService.fitToGeom(position.geom);
        } else if (position?.longitude && position?.latitude) {
          this.mapService.zoomTo(fromLonLat([position.longitude, position.latitude]), 16);
        } else {
          this.notificationService.error($localize`:Zoom Position|No entity found:Aucune entité n'a été trouvée`);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.notificationService.error(error.error.ErrorResponse.message.split(';')[0]);
      },
    });
  }
}
