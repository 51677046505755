import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {
  FeatureOverlay,
  LayerEditionValidationConfiguration,
  LayerProperty,
  LayerSubtype,
  LayerType,
} from '@core/model/application-api/layer.model';
import { LayerConfig, WfsLayerConfig, WmsLayerConfig } from '@core/model/layer-config.model';
import { MapService } from '@core/services/map.service';
import { DataLayerConfig } from '@feature/client-carto-app/data-layer-config.model';
import { EditionVariable } from '@widgets/widgets-filter-cql.service';
import { UpdatedStyles } from './layer-base-style-tab/layer-base-style-tab.component';
import { HeatMapUpdate } from './layer-heatmap-tab/layer-heatmap-tab.component';
import { LegendUpdate } from './layer-legend-tab/layer-legend-tab.component';

@Component({
  selector: 'smv-layer-properties',
  templateUrl: './layer-properties.component.html',
  styleUrls: ['./layer-properties.component.scss'],
})
export class LayerPropertiesComponent implements OnInit {
  @Input() layer?: DataLayerConfig;
  @Input() readonly = false;
  @Output() closePanel = new EventEmitter<void>();
  @Output() saveLayer = new EventEmitter<LayerConfig>();

  public infoPanelConfig?: LayerConfig;
  public layerProperties?: LayerProperty[];
  public validConfig?: LayerEditionValidationConfiguration;
  public styleUpdate?: UpdatedStyles;
  public tooltip?: FeatureOverlay;
  public legendConfig?: LegendUpdate;
  public heatmapConfig?: HeatMapUpdate;
  public hasAttributeTab = true;
  public hasValidationTab = false;
  public hasTooltipTab = true;
  public hasHeatMapTab = false;
  public hasLegendTab = false;
  public hasStyleTab = false;
  public hasStyleActions = false;

  private savedStyle?: UpdatedStyles;
  private hasApplied = false;

  get canSave(): boolean {
    let baseTab = !!this.infoPanelConfig && !!this.layerProperties;
    if (this.hasStyleTab) {
      baseTab = baseTab && !!this.styleUpdate;
    }
    if (this.hasHeatMapTab) {
      baseTab = baseTab && !!this.heatmapConfig;
    }
    if (this.hasValidationTab && this.infoPanelConfig?.editable) {
      baseTab = baseTab && !!this.validConfig;
    }
    if (
      this.infoPanelConfig?.editable &&
      this.infoPanelConfig instanceof WfsLayerConfig &&
      !this.infoPanelConfig.cqlFilter?.includes(`{${EditionVariable.EDITION_VALIDATION}}`)
    ) {
      baseTab = false;
    }
    if (this.infoPanelConfig?.editable && !this.styleUpdate?.useEditionStyle) {
      baseTab = false;
    }
    return baseTab;
  }

  get saveError(): string {
    if (!this.infoPanelConfig) {
      return $localize`La page d'information comporte une erreur`;
    }
    if (!this.layerProperties) {
      return $localize`La page des attributs comporte une erreur`;
    }
    if (!this.styleUpdate) {
      return $localize`La page des styles comporte une erreur`;
    }
    if (this.hasValidationTab && this.infoPanelConfig?.editable && !this.validConfig) {
      return $localize`La page de validation comporte une erreur`;
    }
    if (
      this.infoPanelConfig.editable &&
      this.infoPanelConfig instanceof WfsLayerConfig &&
      !this.infoPanelConfig.cqlFilter?.includes(`{${EditionVariable.EDITION_VALIDATION}}`)
    ) {
      return $localize`Le filtre CQL doit contenir la variable {EDITION_VALIDATION} quand une couche est éditable.`;
    }
    if (this.infoPanelConfig.editable && !this.styleUpdate.useEditionStyle) {
      return $localize`Le style d'édition doit être activé quand une couche est éditable.`;
    }
    if (!this.heatmapConfig) {
      return $localize`La page de la carte de chaleur comporte une erreur`;
    }
    return '';
  }

  constructor(private mapService: MapService) {}

  ngOnInit(): void {
    this.hasStyleTab = !this.readonly && !this.layer?.config.baseLayer;
    this.hasHeatMapTab = !this.readonly && this.layer?.config.subtype === LayerSubtype.HEATMAP;
    this.hasLegendTab = this.hasStyleTab && !this.hasHeatMapTab;

    this.hasValidationTab = !this.readonly;

    if (this.readonly && this.layer) {
      this.hasTooltipTab = this.layer.config.featureOverlay.active;
      this.hasAttributeTab = !!this.layer.config.properties?.length;
    }

    this.savedStyle = {
      useEditionStyle: this.layer?.config.useEditionStyle ?? false,
      baseStyle: this.layer?.config.customStyle,
      dynamicStyles: this.layer?.config.dynamicStyle,
    };
  }

  saveProperties(): void {
    if (this.infoPanelConfig) {
      this.infoPanelConfig.properties = this.layerProperties;
      if (this.infoPanelConfig.editable) {
        this.infoPanelConfig.validationConfiguration = this.validConfig;
      }
      if (this.tooltip) {
        this.infoPanelConfig.featureOverlay = this.tooltip;
      }
      if (this.legendConfig) {
        this.infoPanelConfig.isLegendDisplayed = this.legendConfig.display;
        if (this.infoPanelConfig.type === LayerType.WMS) {
          (this.infoPanelConfig as WmsLayerConfig).legendUrl = this.legendConfig.url;
        }
      }
      if (this.heatmapConfig) {
        this.infoPanelConfig.heatmap = this.heatmapConfig;
      }
      this.infoPanelConfig.useEditionStyle = this.styleUpdate?.useEditionStyle ?? false;
      this.infoPanelConfig.useCustomSld = !!this.styleUpdate?.baseStyle;
      this.infoPanelConfig.customStyle = this.styleUpdate?.baseStyle;
      this.infoPanelConfig.dynamicStyle = this.styleUpdate?.dynamicStyles;
      this.saveLayer.emit(this.infoPanelConfig);
    }
  }

  closeProperties(): void {
    if (this.layer && this.hasApplied) {
      this.layer.config.useEditionStyle = this.savedStyle?.useEditionStyle ?? false;
      this.layer.config.useCustomSld = !!this.savedStyle?.baseStyle;
      this.layer.config.customStyle = this.savedStyle?.baseStyle;
      this.layer.config.dynamicStyle = this.savedStyle?.dynamicStyles;
      this.mapService.updateStyle(this.layer);
      this.hasApplied = false;
    }
    this.closePanel.emit();
  }

  applyStyle(): void {
    if (this.layer) {
      this.layer.config.useEditionStyle = this.styleUpdate?.useEditionStyle ?? false;
      this.layer.config.useCustomSld = !!this.styleUpdate?.baseStyle;
      this.layer.config.customStyle = this.styleUpdate?.baseStyle;
      this.layer.config.dynamicStyle = this.styleUpdate?.dynamicStyles;
      this.mapService.updateStyle(this.layer);

      this.hasApplied = true;
    }
  }

  getStyle(): string | undefined {
    if (this.layer && this.layer.config instanceof WmsLayerConfig) {
      this.layer.config.useCustomSld = !!this.styleUpdate?.baseStyle;
      this.layer.config.customStyle = this.styleUpdate?.baseStyle;
      this.layer.config.dynamicStyle = this.styleUpdate?.dynamicStyles;
      const sld = this.layer.config.styleToSld();
      this.layer.config.useCustomSld = !!this.savedStyle?.baseStyle;
      this.layer.config.customStyle = this.savedStyle?.baseStyle;
      this.layer.config.dynamicStyle = this.savedStyle?.dynamicStyles;

      return sld;
    }
    return;
  }

  exportStyle(): void {
    const style = this.getStyle();
    if (style) {
      const filename = this.layer?.config.shortName + '.sld';
      const blob = new Blob([style], { type: 'text/xml;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.click();
    }
  }

  tabChanged(event: MatTabChangeEvent): void {
    this.hasStyleActions = event.tab.textLabel == 'Style';
  }
}
