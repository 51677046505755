<!-- Form Section -->
<div id="bloc-formulaire" class="container unselectable">
  <div class="card form-card">
    <div class="card-header form-header">
      <p i18n="Title|Farm Informations">Informations de l'exploitation</p>
    </div>
    <div class="card-body">
      <form [formGroup]="form">
        <div class="row">
          <!-- Nom de l'exploitation -->
          <div class="col-12 mb-2">
            <mat-form-field appearance="outline" class="form-control">
              <mat-label i18n="Label|Farm name">Nom de l'exploitation</mat-label>
              <input
                matInput
                formControlName="name"
                placeholder="Entrez le nom de votre exploitation"
                i18n-placeholder
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <!-- Numéro SIRET -->
          <div class="col-12 mb-2">
            <mat-form-field appearance="outline" class="form-control">
              <mat-label i18n="Label|Siret">Numéro SIRET</mat-label>
              <input matInput formControlName="siret" placeholder="Entrez votre numéro de SIRET" i18n-placeholder />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <!-- Adresse -->
          <div class="col-12 mb-2">
            <mat-form-field appearance="outline" class="form-control">
              <mat-label i18n="Label|Address">Adresse</mat-label>
              <input matInput formControlName="address" placeholder="Entrez votre adresse" i18n-placeholder />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mb-2">
            <div class="d-flex">
              <!-- Code postal -->
              <mat-form-field appearance="outline" class="form-control me-2">
                <mat-label i18n="Label|Zip Code">Code postal</mat-label>
                <input matInput formControlName="zipCode" placeholder="Entrez votre code postal" i18n-placeholder />
              </mat-form-field>

              <!-- Ville -->
              <mat-form-field appearance="outline" class="form-control">
                <mat-label i18n="Label|City">Ville</mat-label>
                <input matInput formControlName="city" placeholder="Entrez votre ville" i18n-placeholder />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <!-- Save Button -->
          <div class="col-12 d-flex justify-content-right mb-3">
            <button mat-raised-button [disabled]="form.invalid" (click)="save()" class="btn-save" color="primary" i18n>
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
