import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParameterId, WidgetType } from '@core/model/application-api/widget.model';
import { WidgetService } from '@widgets/widgets.service';
import { Feature, FeatureCollection } from 'geojson';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SearchAddressService {
  private baseUrl = 'https://api-adresse.data.gouv.fr/search/';
  private limitSearchResult = 5;

  constructor(
    private http: HttpClient,
    private widgetService: WidgetService
  ) {
    this.widgetService
      .getWidgetContextItem(WidgetType.SEARCH)
      .getStream()
      .subscribe((widget) => {
        if (widget) {
          this.limitSearchResult =
            (widget.parameters.find((parameter) => parameter.id == ParameterId.RESULT_COUNT)?.value as number) ?? 5;
        }
      });
  }

  searchAddress(search: string): Observable<Feature[]> {
    const params = new HttpParams().set('q', search).set('limit', this.limitSearchResult);
    return this.http.get<FeatureCollection>(this.baseUrl, { params: params }).pipe(
      map((res) => {
        return res.features;
      })
    );
  }
}
