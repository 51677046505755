import { Component } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { IcareService } from '../icare.service';
import { FilterTypeDataComponent } from '../../components/filter-type-data/filter-type-data.component';
import { IcareFilterObservationsComponent } from './icare-filter-observations/icare-filter-observations.component';
import { IcareFilterSimulationsComponent } from './icare-filter-simulations/icare-filter-simulations.component';

@Component({
  selector: 'smv-icare-filter',
  standalone: true,
  imports: [FilterTypeDataComponent, IcareFilterObservationsComponent, IcareFilterSimulationsComponent],
  templateUrl: './icare-filter.component.html',
  styleUrls: ['./icare-filter.component.scss'],
})
export class IcareFilterComponent {
  public filter = this.icareService.filterState.getValue().displayFilter;
  public authorizedSimulation = true;

  constructor(
    private icareService: IcareService,
    private authService: AuthService
  ) {
    this.authorizedSimulation = this.authService.isAuthorizedSimulationIcareInSync();
    if (!this.authorizedSimulation) {
      this.icareService.filterState.setParticularValue('displayFilter', 'FILTER_OBSERVATION');
    }
  }

  onFilterChange() {
    this.icareService.filterState.setParticularValue('displayFilter', this.filter);
  }
}
