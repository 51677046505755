<div class="smv-page-layout">
  <h1 i18n>Applications accessibles</h1>
  <mat-divider></mat-divider>
  @if (currentUser?.authorizedMapCreation || currentUser?.superAdmin) {
    <div routerLink="/applications" [state]="{ addAction: true }" class="create-app" i18n>
      Vous pouvez créer ou modifier une application depuis la page <span>Mes applications</span>.
    </div>
  }
</div>

<div class="smv-large-page-layout">
  <div class="application-list">
    @for (application of applications$ | async; track application) {
      <mat-card class="application" [routerLink]="['/applications', application.id]">
        <mat-card-title class="header">
          <div class="header-title">
            @if (currentUser?.id === application.idProprietaire) {
              <div matTooltip="Vous êtes propriétaire de l'application" i18n-matTooltip>
                <mat-icon>co_present</mat-icon>
              </div>
            }
            {{ application.nom }}
          </div>
          @if (!application.publique) {
            <div matTooltip="Cette application est privée" i18n-matTooltip class="private-app-icon">
              <mat-icon>lock</mat-icon>
            </div>
          }
        </mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content class="content">
          @if (application.logoName) {
            <img
              src="{{ baseUrl }}/{{ application.id }}/logo"
              [alt]="application.logoName"
              matTooltip="{{ application.logoName }}"
              class="logo"
            />
          }
          @if (application.description) {
            <div class="description">{{ application.description }}</div>
          }
        </mat-card-content>
      </mat-card>
    }
  </div>
</div>
