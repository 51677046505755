import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { ServerStatusService } from '@core/services/server-status.service';
import { environment } from '@env/environment';
import { Observable, catchError, throwError } from 'rxjs';

export const backendResponseInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  if (req.url.startsWith(environment.apiUrl) || req.url.indexOf('services-webapp-syngentamap') >= 0) {
    const serverStatusService = inject(ServerStatusService);
    return next(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (Math.floor(error.status / 100) == 5) {
          serverStatusService.checkServerStatus();
        }
        return throwError(() => error);
      })
    );
  } else {
    return next(req);
  }
};
