<mat-card cdkScrollable class="side-panel">
  <mat-card-title>
    <div *ngIf="activePanel; else defaultTitle">{{ panelTitle }}</div>
    <ng-template #defaultTitle>
      <div i18n="Application panel|Title">Modifier les couches</div>
    </ng-template>
  </mat-card-title>
  <mat-card-content>
    <div class="panel-actions" *ngIf="!activePanel">
      <button mat-stroked-button i18n="Application panel" (click)="openLayerGroupEditor()">
        Ajouter un groupe de couches
      </button>
      <button mat-stroked-button i18n="Application panel" disabled>Créer une nouvelle couche</button>
      <button mat-stroked-button i18n="Application panel" (click)="addExternalLayer()">
        Créer une couche WMS/WFS/WMTS/GeoJSON
      </button>
      <button mat-stroked-button (click)="sortLayers()" i18n="Application panel">Réorganiser les couches</button>
    </div>
    <ng-container [ngSwitch]="activePanel">
      <smv-layer-sort
        *ngSwitchCase="EditionPanel.SORT"
        [layerGroups]="layerGroups"
        (closePanel)="updateLayers($event)"
      />
      <smv-layer-group-editor
        *ngSwitchCase="EditionPanel.ADD_LAYER_GROUP"
        [layerGroup]="editedLayerGroup"
        (closePanel)="addOrUpdateGroup($event)"
      />
      <smv-external-layer
        *ngSwitchCase="EditionPanel.ADD_EXTERNAL"
        [preferredProjection]="application().parsedConfig.projection"
        [(creating)]="isCreating"
        (saveLayer)="createLayer($event)"
        (closePanel)="cancelUpdates()"
      />
      <smv-layer-properties
        *ngSwitchCase="EditionPanel.MODIFY_PROPERTIES"
        [layer]="editedLayer"
        (closePanel)="cancelUpdates()"
        (saveLayer)="saveLayer($event)"
      />
      <smv-data-layers
        *ngSwitchDefault
        [layerGroups]="layerGroups"
        [editable]="true"
        (renameLayerGroup)="openLayerGroupEditor($event)"
        (layerOrGroupRemoved)="updateLayers(layerGroups)"
        (editLayerProperties)="openLayerProperties($event)"
      />
    </ng-container>
  </mat-card-content>
  <mat-card-actions *ngIf="!activePanel">
    <button mat-flat-button color="primary" (click)="closePanel.emit()">
      <mat-icon>arrow_back</mat-icon>
      <ng-container i18n="Button|Layer manager action">Revenir à la carte</ng-container>
    </button>
  </mat-card-actions>
</mat-card>
