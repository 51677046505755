@if (!isNoHeaderFooter) {
  <smv-header [isLoggedIn]="isLoggedIn" (toggleMenu)="drawer.toggle()"></smv-header>
}
<mat-drawer-container hasBackdrop>
  <mat-drawer mode="over" #drawer>
    <smv-sidemenu (closeSideNav)="drawer.close()"></smv-sidemenu>
  </mat-drawer>
  <mat-drawer-content>
    <div class="main-content">
      @if (isBackendUp) {
        <router-outlet></router-outlet>
      } @else {
        <h1 i18n="Main Content|Services in maintenance">
          Les services MapView sont actuellement en maintenance, veuillez patienter.
        </h1>
      }
    </div>
    @if (!isLoggedIn && !isNoHeaderFooter) {
      <smv-anonymous-footer></smv-anonymous-footer>
    }
  </mat-drawer-content>
</mat-drawer-container>
