<p>
  <ng-container i18n="Layer base style">Style par défaut de la couche :</ng-container>
  <span class="default-style-name"> {{ defaultStyle }} </span>
</p>

<div class="toggle">
  <mat-slide-toggle [formControl]="customStyleControl" i18n="Layer base style">
    Définir un style personnalisé
  </mat-slide-toggle>
  <smv-help-overlay i18n="Layer base style">
    Si cette option est activée, le style de la couche est défini manuellement puis est converti en SLD qui sera envoyé
    au serveur.
  </smv-help-overlay>
</div>

<ng-container *ngIf="customStyleControl.value">
  <div class="toggle" *ngIf="layer.config?.type === 'WFS'">
    <mat-slide-toggle [formControl]="editionStyleControl" i18n="Layer base style">
      Utiliser le style d'édition
    </mat-slide-toggle>
    <smv-help-overlay i18n="Layer base style">
      Si cette option est activée, le style de la couche va être configuré avec un style dynamique spécifique pour
      l'édition.
    </smv-help-overlay>
  </div>

  <div class="category-actions">
    <button mat-stroked-button (click)="resetStyle()">
      <mat-icon>refresh</mat-icon>
      <ng-container i18n="Layer base style">Regénérer les styles par défaut</ng-container>
    </button>
  </div>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header i18n="Layer base style">Style de base</mat-expansion-panel-header>
    <smv-feature-style-form [formGroup]="baseStyleForm" [layerGeometry]="layer.config.geometryType" />
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header i18n="Layer base style">Styles dynamiques</mat-expansion-panel-header>

    <div>
      <smv-form-field-wrapper
        [control]="dynamicStyleControl"
        controlType="select"
        [selectOptions]="dynamicStyleTypes"
        required
        hasHelp
      >
        <span mat-label i18n="Layer base style">Type de style dynamique</span>
        <div help>
          <p i18n="Layer base style">« Aucun » : le style de base est appliqué</p>
          <p i18n="Layer base style">
            « Par catégorie » : ajoute un ensemble de règles définies à partir des valeurs distinctes d'un attribut ou
            d'une expression (filtre CQL)
          </p>
          <p i18n="Layer base style">
            « Dégradé » : ajoute un ensemble de règles à partir de plages de valeurs numériques
          </p>
        </div>
      </smv-form-field-wrapper>

      <div *ngIf="dynamicStyleControl.value !== 'aucun'">
        <form [formGroup]="dynamicStyleForm">
          <div class="field-grid">
            <smv-form-field-wrapper
              formControlName="attribute"
              controlType="select"
              [selectOptions]="attributes"
              required
            >
              <span mat-label i18n="Layer base style">Attribut cible</span>
            </smv-form-field-wrapper>

            <smv-form-field-wrapper formControlName="palette" controlType="select" [selectOptions]="palettes" required>
              <span mat-label i18n="Layer base style">Palette de couleurs</span>
            </smv-form-field-wrapper>

            <ng-container *ngIf="dynamicStyleControl.value === 'gradient'">
              <smv-form-field-wrapper formControlName="minValue" type="number">
                <span mat-label i18n="Layer base style">Valeur minimale</span>
              </smv-form-field-wrapper>
              <smv-form-field-wrapper formControlName="maxValue" type="number">
                <span mat-label i18n="Layer base style">Valeur maximale</span>
              </smv-form-field-wrapper>
              <mat-slide-toggle formControlName="useFirstRangeIfLesser" i18n="Layer base style">
                Utiliser la première plage lorsque la valeur est inférieure
              </mat-slide-toggle>
              <mat-slide-toggle formControlName="useLastRangeIfGreater" i18n="Layer base style">
                Utiliser la dernière plage lorsque la valeur est supérieure
              </mat-slide-toggle>
              <smv-form-field-wrapper formControlName="nbRanges" type="number">
                <span mat-label i18n="Layer base style">Nombre de plages</span>
              </smv-form-field-wrapper>
            </ng-container>
          </div>

          <smv-style-rule-form
            [layerGeometry]="layer.config.geometryType"
            [(rules)]="rulesForm"
            [readonly]="rulesReadonly"
            [isSortingRules]="isSortingRules"
          />

          <div class="category-actions" *ngIf="dynamicStyleControl.value === 'categories'">
            <button mat-stroked-button (click)="toggleSortRules(true)" *ngIf="!isSortingRules">
              <mat-icon>swap_vert</mat-icon>
              <ng-container i18n="Layer rule form">Réorganiser</ng-container>
            </button>
            <button mat-stroked-button (click)="toggleSortRules(false)" *ngIf="isSortingRules">
              <ng-container i18n="Layer rule form">Terminer la réorganisation</ng-container>
            </button>
            <smv-help-overlay *ngIf="isSortingRules" [closeAfter]="3000" i18n="Layer rule form|Sorting help">
              Vous pouvez réorganiser les lignes du tableau en cliquant et faisant glisser. Appuyer sur le bouton
              terminer pour pouvoir modifier les catégories.
            </smv-help-overlay>
            <button
              mat-stroked-button
              (click)="searchCategories()"
              *ngIf="!searchCategoriesDisabled && !isSortingRules"
            >
              <mat-icon>format_list_bulleted</mat-icon>
              <ng-container i18n="Layer rule form">Classer</ng-container>
            </button>
            <button mat-stroked-button (click)="addRuleForm()" *ngIf="!isSortingRules">
              <mat-icon>add</mat-icon>
              <ng-container i18n="Layer rule form">Ajouter une règle</ng-container>
            </button>
            <button
              mat-stroked-button
              (click)="resetRules()"
              matTooltip="Supprimer toutes les règles"
              i18n-matTooltip
              *ngIf="!isSortingRules"
            >
              <mat-icon>delete</mat-icon>
              <ng-container i18n="Layer rule form">Supprimer</ng-container>
            </button>
          </div>
        </form>
      </div>
    </div>
  </mat-expansion-panel>
</ng-container>
