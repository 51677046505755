import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { editionCustomStyle, editionDynamicStyle } from '@assets/styles/edition-style';
import { DropdownOption } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { WmsLayerConfig } from '@core/model/layer-config.model';
import { LayerDynamicStyle } from '@core/model/layer-dynamic-style';
import { LayerDynamicStyleCategories } from '@core/model/layer-dynamic-style-categories';
import { LayerDynamicStyleGradient } from '@core/model/layer-dynamic-style-gradient';
import { CustomLayerStyle } from '@core/model/layer-style.model';
import { Palette } from '@core/model/styles/style-config.model';
import { StyleRule } from '@core/model/styles/style-rule.model';
import { MapService } from '@core/services/map.service';
import { DataLayerConfig } from '@feature/client-carto-app/data-layer-config.model';
import {
  DYNAMIC_STYLE_TYPE_OPTIONS,
  DynamicStyleType,
  PALETTES_DEFINITION,
  PALETTE_OPTIONS,
  toOptions,
} from './style-form-config.model';
import {
  BaseStyleForm,
  DynamicStyleForm,
  RuleForm,
  buildBaseForm,
  buildRuleForm,
  formToDynamicStyle,
  formToStyle,
  prepareBaseStyle,
  prepareRule,
} from './style-form.model';

import { isNil } from 'lodash-es';
import VectorSource from 'ol/source/Vector';
import VectorTile from 'ol/source/VectorTile';
import { debounce, filter, interval } from 'rxjs';

export interface UpdatedStyles {
  useEditionStyle: boolean;
  baseStyle?: CustomLayerStyle;
  dynamicStyles?: LayerDynamicStyle;
}

@Component({
  selector: 'smv-layer-base-style-tab',
  templateUrl: './layer-base-style-tab.component.html',
  styleUrls: ['./layer-base-style-tab.component.scss'],
})
export class LayerBaseStyleTabComponent implements OnInit {
  @Input() layer!: DataLayerConfig;
  @Output() styleUpdate = new EventEmitter<UpdatedStyles>();

  public readonly dynamicStyleTypes = DYNAMIC_STYLE_TYPE_OPTIONS;
  public readonly palettes = PALETTE_OPTIONS;
  private readonly palettesDefinition = PALETTES_DEFINITION;

  public defaultStyle?: string;
  public attributes: DropdownOption<string>[] = [];

  public customStyleControl = new FormControl<boolean>(false);
  public editionStyleControl = new FormControl<boolean>(false);
  public dynamicStyleControl = new FormControl<DynamicStyleType>(this.dynamicStyleTypes[0].value);
  public baseStyleForm = new FormGroup<BaseStyleForm>({});
  public dynamicStyleForm = new FormGroup<DynamicStyleForm>({
    attribute: new FormControl<string | null>(null),
    palette: new FormControl<Palette | null>(null),
  });
  public rulesForm = new FormArray<FormGroup<RuleForm>>([]);
  public searchCategoriesDisabled = true;
  public isSortingRules = false;
  public rulesReadonly = false;

  private gradientMinValue = new FormControl<number | null>(0, Validators.min(0));
  private gradientMaxValue = new FormControl<number | null>(100, Validators.min(1));
  private gradientUseLastRange = new FormControl<boolean | null>(false);
  private gradientUseFirstRange = new FormControl<boolean | null>(false);
  private gradientNumberOfRanges = new FormControl<number | null>(10, [Validators.min(2), Validators.max(10)]);

  private allForm?: FormArray;

  private saveBaseStyle?: CustomLayerStyle;
  private saveDynamicStyle?: LayerDynamicStyle;

  constructor(private mapService: MapService) {}

  ngOnInit(): void {
    if (
      this.layer.olLayer?.getSource() instanceof VectorSource ||
      this.layer.olLayer?.getSource() instanceof VectorTile
    ) {
      this.searchCategoriesDisabled = false;
    }
    if (this.layer.config instanceof WmsLayerConfig) {
      this.defaultStyle = this.layer.config.activeStyle;
    }

    this.editionStyleControl.patchValue(this.layer.config.useEditionStyle);
    this.baseStyleForm = buildBaseForm(this.layer.config.geometryType);
    this.attributes = toOptions(this.layer.config.properties?.map((prop) => prop.name) ?? []);

    this.allForm = new FormArray([
      this.customStyleControl,
      this.editionStyleControl,
      this.baseStyleForm,
      this.dynamicStyleControl,
      this.dynamicStyleForm,
      this.rulesForm,
    ]);

    this.initSubscriptions();
    this.initStyle();
  }

  initSubscriptions() {
    this.editionStyleControl.valueChanges.subscribe((value) => this.toggleEditionStyle(value ?? false));
    this.allForm?.valueChanges.pipe(debounce(() => interval(500))).subscribe(() => this.updateCustomStyles());

    this.dynamicStyleControl.valueChanges.subscribe((style) => {
      if (style === 'gradient') {
        this.addGradientFields();
      } else {
        this.removeGradientFields();
      }
    });

    this.dynamicStyleForm.valueChanges
      .pipe(filter(() => this.dynamicStyleControl.value === 'gradient'))
      .subscribe(() => {
        this.generateGradientCategories(this.dynamicStyleForm);
      });
  }

  initStyle(initBaseStyle?: CustomLayerStyle, initDynamicStyle?: LayerDynamicStyle) {
    const baseStyle = initBaseStyle ?? this.layer.config.customStyle;
    if (baseStyle) {
      this.baseStyleForm.patchValue(prepareBaseStyle(baseStyle));
      this.customStyleControl.patchValue(true);
    } else {
      this.baseStyleForm.patchValue(prepareBaseStyle(CustomLayerStyle.defaultStyle));
    }
    const dynamicStyle = initDynamicStyle ?? this.layer.config.dynamicStyle;
    if (dynamicStyle) {
      this.customStyleControl.patchValue(true);
      if (dynamicStyle instanceof LayerDynamicStyleCategories) {
        this.dynamicStyleControl.patchValue('categories');
      } else if (dynamicStyle instanceof LayerDynamicStyleGradient) {
        this.dynamicStyleControl.patchValue('gradient');
        this.dynamicStyleForm.patchValue({ nbRanges: dynamicStyle.categories.length });
      }
      this.dynamicStyleForm.patchValue(dynamicStyle);
      this.rulesForm.clear();
      dynamicStyle.categories.forEach((rule) => this.addRuleForm(rule));
    } else {
      this.dynamicStyleControl.patchValue('aucun');
      this.dynamicStyleForm.reset();
      this.rulesForm.clear();
    }
  }

  resetStyle() {
    this.initStyle(CustomLayerStyle.defaultStyle);
    this.resetRules();
    this.dynamicStyleControl.patchValue('aucun');
  }

  private toggleEditionStyle(state: boolean) {
    if (state) {
      if (this.layer.config.customStyle) {
        this.layer.config.customStyle.setBaseStyle(formToStyle(this.baseStyleForm));
      } else {
        this.saveBaseStyle = formToStyle(this.baseStyleForm);
      }
      const dynamicStyle = formToDynamicStyle(
        this.dynamicStyleForm,
        this.rulesForm,
        this.dynamicStyleControl.value ?? 'aucun'
      );
      if (dynamicStyle) {
        if (this.layer.config.dynamicStyle) {
          this.layer.config.dynamicStyle.setBaseStyle(dynamicStyle);
        } else {
          this.saveDynamicStyle = dynamicStyle;
        }
      }
      this.initStyle(new CustomLayerStyle(editionCustomStyle), new LayerDynamicStyleCategories(editionDynamicStyle));
    } else {
      const baseStyle = new CustomLayerStyle(this.layer.config.customStyle?.getBaseStyle()) ?? this.saveBaseStyle;
      const dynamicBaseStyle = this.layer.config.dynamicStyle?.getBaseStyle();
      let dynamicStyle;
      if (dynamicBaseStyle) {
        dynamicStyle =
          dynamicBaseStyle.useFirstRangeIfLesser === undefined
            ? new LayerDynamicStyleCategories(dynamicBaseStyle)
            : new LayerDynamicStyleGradient(dynamicBaseStyle);
      } else {
        dynamicStyle = this.saveDynamicStyle;
      }
      this.initStyle(baseStyle, dynamicStyle);
    }
  }

  updateCustomStyles() {
    if (this.allForm?.invalid) {
      this.styleUpdate.emit();
    } else {
      const custom = this.customStyleControl.value;
      const baseStyle = formToStyle(this.baseStyleForm, this.layer.config.customStyle?.getBaseStyle());
      const dynamicStyle = formToDynamicStyle(
        this.dynamicStyleForm,
        this.rulesForm,
        this.dynamicStyleControl.value ?? 'aucun'
      );
      if (this.saveBaseStyle) {
        baseStyle.setBaseStyle(this.saveBaseStyle);
      }
      if (this.saveDynamicStyle) {
        dynamicStyle?.setBaseStyle(this.saveDynamicStyle);
      }

      this.styleUpdate.emit({
        useEditionStyle: this.editionStyleControl.value ?? false,
        baseStyle: custom ? baseStyle : undefined,
        dynamicStyles: custom ? dynamicStyle : undefined,
      });
    }
  }

  private addGradientFields(): void {
    this.dynamicStyleForm.addControl('minValue', this.gradientMinValue);
    this.dynamicStyleForm.addControl('maxValue', this.gradientMaxValue);
    this.dynamicStyleForm.addControl('useFirstRangeIfLesser', this.gradientUseFirstRange);
    this.dynamicStyleForm.addControl('useLastRangeIfGreater', this.gradientUseLastRange);
    this.dynamicStyleForm.addControl('nbRanges', this.gradientNumberOfRanges);
  }

  private removeGradientFields(): void {
    this.dynamicStyleForm.removeControl('minValue');
    this.dynamicStyleForm.removeControl('maxValue');
    this.dynamicStyleForm.removeControl('useFirstRangeIfLesser');
    this.dynamicStyleForm.removeControl('useLastRangeIfGreater');
    this.dynamicStyleForm.removeControl('nbRanges');
  }

  searchCategories() {
    let values = [];
    const source = this.layer.olLayer?.getSource();
    const attribute = this.dynamicStyleForm.value.attribute;
    if (source && attribute) {
      const sourceVector = source as VectorSource | VectorTile;
      if (sourceVector.getFeaturesInExtent) {
        const extent = this.mapService.getMapExtentCoordinates();
        values = sourceVector
          .getFeaturesInExtent(extent)
          .map((feature) => feature.get(attribute))
          .filter((value, index, array) => array.indexOf(value) === index);
      } else {
        values = (sourceVector as VectorSource)
          .getFeatures()
          .map((feature) => feature.get(attribute))
          .filter((value, index, array) => array.indexOf(value) === index);
      }

      for (const value of values) {
        const style = this.layer.config.customStyle
          ? new CustomLayerStyle(this.layer.config.customStyle.toModel())
          : new CustomLayerStyle();
        const palette = this.dynamicStyleForm.value.palette;

        if (palette) {
          const colors = this.palettesDefinition[palette];
          style.applyCategorieColor(colors[(this.rulesForm.length + this.rulesForm.length) % colors.length]);
        }
        const ruleFilter = attribute + "='" + value + "'";
        const newRule = new StyleRule({ label: value, cqlValue: ruleFilter, style: style.toModel() });
        newRule.style = style;
        //On verifie que la règle n'existe pas déjà
        if (this.rulesForm.value.find((rule) => rule.cqlValue == ruleFilter) == undefined) {
          this.addRuleForm(newRule);
        }
      }
    }
  }

  private generateGradientCategories(form: FormGroup<DynamicStyleForm>) {
    this.rulesForm.clear();
    const values = form.value;
    if (!isNil(values.minValue) && !isNil(values.maxValue) && !isNil(values.nbRanges)) {
      for (let index = 0; index < values.nbRanges; index++) {
        const min = values.minValue + ((values.maxValue - values.minValue) / values.nbRanges) * index;
        const max = values.minValue + ((values.maxValue - values.minValue) / values.nbRanges) * (index + 1);
        let rule = values.attribute + ' BETWEEN ' + min + ' AND ' + max;
        if (index == 0 && values.useFirstRangeIfLesser) {
          rule = values.attribute + ' < ' + max;
        }
        if (index == values.nbRanges - 1 && values.useLastRangeIfGreater) {
          rule = values.attribute + ' > ' + min;
        }

        const newStyle = this.layer.config.customStyle
          ? new CustomLayerStyle(this.layer.config.customStyle.toModel())
          : new CustomLayerStyle();
        if (values.palette) {
          const colors = this.palettesDefinition[values.palette];
          newStyle.applyCategorieColor(colors[this.rulesForm.length % colors.length]);
        }

        this.addRuleForm(new StyleRule({ cqlValue: rule, style: newStyle.toModel() }));
      }
    }
  }

  toggleSortRules(state: boolean) {
    this.isSortingRules = state;
    this.rulesReadonly = state;
    if (state) {
      this.rulesForm.disable();
    } else {
      this.rulesForm.enable();
    }
  }

  addRuleForm(rule?: StyleRule): void {
    const form: FormGroup<RuleForm> = buildRuleForm(this.layer.config.geometryType);
    this.rulesForm.push(form);

    if (rule) {
      form.patchValue({ ...prepareRule(rule) });
    } else {
      form.patchValue({
        style: prepareBaseStyle(
          formToStyle(this.baseStyleForm),
          this.palettesDefinition[this.dynamicStyleForm.value.palette ?? 'no-color'],
          this.rulesForm.length
        ),
      });
    }
  }

  resetRules() {
    this.rulesForm.clear();
  }
}
