import { rgbToHex } from '@core/utils/layer-style.utils';
import { XmlTag } from '@core/utils/xml.utils';
import { isNil } from 'lodash-es';
import { FeatureLike } from 'ol/Feature';
import { Fill, Stroke, Text } from 'ol/style';
import { TextAlignment } from './style-config.model';

export interface TextStyleModel {
  text?: string | null;
  textMargin?: number | null;
  offsetX?: number | null;
  offsetY?: number | null;
  fontSize?: number | null;
  textFillColor?: string | null;
  alignment?: TextAlignment | null;
  textStrokeColor?: string | null;
  textBackGroundColor?: string | null;
  textRotation?: number | null;
}

interface BaseTextStyle {
  text: string;
  textMargin: number;
  offsetX: number;
  offsetY: number;
  fontSize: number;
  textFillColor: string;
  alignment: TextAlignment;
  textStrokeColor: string;
  textBackGroundColor: string;
  textRotation: number;
}

export class TextStyle {
  private baseStyle: BaseTextStyle;

  public text = '';
  public textMargin = 0;
  public offsetX = 0;
  public offsetY = 0;

  public fontSize = 11;
  public textFillColor = '#333333';
  public alignment: TextAlignment = 'center';
  public textStrokeColor = 'rgba(200, 200, 200, 0.6)';
  public textBackGroundColor = '';
  public textRotation = 0;

  constructor(style?: TextStyleModel | null) {
    if (style) {
      Object.assign(this, style);
    }
    this.baseStyle = this;
  }

  public setBaseStyle(baseStyle: TextStyle) {
    this.baseStyle = baseStyle;
  }

  toModel(): TextStyleModel {
    return {
      text: this.text,
      textMargin: this.textMargin,
      offsetX: this.offsetX,
      offsetY: this.offsetY,
      fontSize: this.fontSize,
      textFillColor: this.textFillColor,
      alignment: this.alignment,
      textStrokeColor: this.textStrokeColor,
      textBackGroundColor: this.textBackGroundColor,
      textRotation: this.textRotation,
    };
  }

  setDefaultValues() {
    this.text = '';
    this.textMargin = 0;
    this.textFillColor = '#333333';
    this.textStrokeColor = 'rgba(200, 200, 200, 0.6)';
    this.textBackGroundColor = '';
    this.fontSize = 11;
    this.offsetX = 0;
    this.offsetY = 0;
    this.alignment = 'center';
    this.textRotation = 0;
  }

  public createTextStyle(feature?: FeatureLike) {
    const align = this.getTextAlignment();
    const baseline = 'middle' as const;
    const size = this.getTextFontSize();
    const offsetX = this.getOffsetX();
    const offsetY = this.getOffsetY() * -1;
    const weight = 'normal'; // ou bold
    const placement = 'point' as const; //ou line
    const maxAngle = 45;
    const overflow = false;
    const rotation = 0;

    const font = weight + ' ' + size + 'px "Open Sans", "Arial Unicode MS", "sans-serif"';
    const fillColor = this.getTextFillColor();
    const backGroundfillColor = this.getTextBackGroundFillColor();
    const outlineColor = this.getTextStrokeColor();
    const outlineWidth = 1;

    let backgroundFill;
    if (backGroundfillColor && backGroundfillColor.length > 0) {
      backgroundFill = new Fill({ color: backGroundfillColor });
    }
    const options = {
      textAlign: align,
      textBaseline: baseline,
      font: font,
      padding: this.getTextPadding(),
      text: this.getTextContent(feature),
      fill: new Fill({ color: fillColor }),
      stroke: new Stroke({ color: outlineColor, width: outlineWidth }),
      offsetX: offsetX,
      offsetY: offsetY,
      placement: placement,
      maxAngle: maxAngle,
      overflow: overflow,
      rotation: rotation,
      backgroundFill: backgroundFill,
    };
    return new Text(options);
  }

  private getTextContent(feature?: FeatureLike) {
    return this.getText().replace(/{(.*?)}/, (a, b) => {
      const value = feature ? feature.get(b) : null;

      return value ? value : '';
    });
  }

  getOffsetY(): number {
    return !isNil(this.offsetY) ? this.offsetY : this.baseStyle.offsetY;
  }

  getOffsetX(): number {
    return !isNil(this.offsetX) ? this.offsetX : this.baseStyle.offsetX;
  }

  getTextPadding(): number[] {
    return !isNil(this.textMargin)
      ? [this.textMargin, this.textMargin, this.textMargin, this.textMargin]
      : [this.baseStyle.textMargin, this.baseStyle.textMargin, this.baseStyle.textMargin, this.baseStyle.textMargin];
  }

  getTextBackGroundFillColor(): string {
    return !isNil(this.textBackGroundColor) ? this.textBackGroundColor : this.baseStyle.textBackGroundColor;
  }

  getTextFontSize(): number {
    return this.fontSize ? this.fontSize : this.baseStyle.fontSize;
  }

  getLabelTag(): XmlTag {
    if (this.getText().indexOf('{') >= 0) {
      return {
        name: 'Label',
        content: [
          {
            name: 'ogc:PropertyName',
            content: this.getText().replace(/\{|\}/g, ''),
          },
        ],
      };
    } else {
      return {
        name: 'Label',
        content: [
          {
            name: 'ogc:Literal',
            content: this.getText(),
          },
        ],
      };
    }
  }

  getFontTag(): XmlTag {
    return {
      name: 'Font',
      content: [
        {
          name: 'CssParameter',
          attributes: { name: 'font-family' },
          content: 'Arial',
        },
        {
          name: 'CssParameter',
          attributes: { name: 'font-size' },
          content: this.getTextFontSize(),
        },
        {
          name: 'CssParameter',
          attributes: { name: 'font-style' },
          content: 'normal',
        },
        {
          name: 'CssParameter',
          attributes: { name: 'font-weight' },
          content: 'bold',
        },
      ],
    };
  }

  getAnchorTag(): XmlTag {
    let x = 0.5;

    switch (this.getTextAlignment()) {
      case 'start':
      case 'left':
        x = 0.0;
        break;
      case 'end':
      case 'right':
        x = 1.0;
        break;
    }

    return {
      name: 'AnchorPoint',
      content: [
        {
          name: 'AnchorPointX',
          content: x,
        },
        {
          name: 'AnchorPointY',
          content: 0.5,
        },
      ],
    };
  }

  textSymbolizerToSld(): XmlTag | undefined {
    let tag: XmlTag;

    if (this.getText()) {
      tag = {
        name: 'TextSymbolizer',
        content: [
          this.getLabelTag(),
          this.getFontTag(),
          {
            name: 'LabelPlacement',
            content: [
              {
                name: 'PointPlacement',
                content: [
                  this.getAnchorTag(),
                  {
                    name: 'Displacement',
                    content: [
                      {
                        name: 'DisplacementX',
                        content: this.getOffsetX(),
                      },
                      {
                        name: 'DisplacementY',
                        content: this.getOffsetY(),
                      },
                    ],
                  },
                  {
                    name: 'Rotation',
                    content: this.getTextRotation(),
                  },
                ],
              },
            ],
          },
          {
            name: 'Fill',
            content: [
              {
                name: 'CssParameter',
                content: rgbToHex(this.getTextFillColor()),
              },
            ],
          },
        ],
      };
      return tag;
    }
    return;
  }

  getText(): string {
    return this.text ?? this.baseStyle.text;
  }

  getTextAlignment(): TextAlignment {
    return !isNil(this.alignment) ? this.alignment : this.baseStyle.alignment;
  }

  getTextFillColor(): string {
    return this.textFillColor ? this.textFillColor : this.baseStyle.textFillColor;
  }

  getTextRotation(): number {
    return !isNil(this.textRotation) ? this.textRotation : this.baseStyle.textRotation;
  }

  getTextStrokeColor(): string {
    return this.textStrokeColor ? this.textStrokeColor : this.baseStyle.textStrokeColor;
  }
}
