@if (layersWithLegend.length) {
  <mat-accordion>
    @for (layer of layersWithLegend; track layer; let isFirst = $first) {
      <mat-expansion-panel [expanded]="isFirst">
        <mat-expansion-panel-header>{{ layer.config.shortName }}</mat-expansion-panel-header>
        @switch (layer.config.type) {
          @case (LayerType.WMS) {
            <smv-wms-legend [layer]="layer" />
          }
          @case (LayerType.WFS) {
            <ng-container *ngTemplateOutlet="vectorLegend"></ng-container>
          }
          @case (LayerType.VECTOR) {
            <ng-container *ngTemplateOutlet="vectorLegend"></ng-container>
          }
          @case (LayerType.VECTOR_TILE) {
            <ng-container *ngTemplateOutlet="vectorLegend"></ng-container>
          }
          @default {
            <ng-container *ngSwitchDefault i18n="Legend">Pas de légende</ng-container>
          }
        }
        <ng-template #vectorLegend><smv-vector-legend [layerConfig]="layer.config" /></ng-template>
      </mat-expansion-panel>
    }
  </mat-accordion>
} @else {
  @if (layers.length) {
    <ng-container i18n="Legend">Aucune légende n'est disponible pour les couches affichées</ng-container>
  } @else {
    <ng-template i18n="Legend">Veuillez afficher une couche</ng-template>
  }
}
