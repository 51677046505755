import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DrawInteractionHelper } from '@core/helpers/draw-interaction.helper';
import { MapService } from '@core/services/map.service';
import { SelectionService } from '@core/services/selection.service';
import { StatisticConfig, TrapFilter } from './statistic.model';
import { StatisticService } from './statistic.service';
import { MatExpansionModule } from '@angular/material/expansion';

type StatisticCapture = 'AUTOMATIC' | 'MANUAL';

type StatisticDrawing = 'POLYGONAL' | 'MANUAL';

@Component({
  selector: 'smv-statistic',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
  ],
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.scss'],
})
export class StatisticComponent {
  @Input() allTrapLayerCode?: string;

  public allTrapsDisplayed = false;
  public captureMode: StatisticCapture = 'AUTOMATIC';
  public drawingMode: StatisticDrawing = 'POLYGONAL';

  public isManualDrawing = false;

  private drawingInteraction?: DrawInteractionHelper;

  constructor(
    private statisticService: StatisticService,
    private mapService: MapService,
    private selectionService: SelectionService
  ) {}

  onDisplayAllTrapsChanged() {
    if (this.allTrapLayerCode) {
      const layer = this.mapService.getLayerByCode(this.allTrapLayerCode);
      if (layer) {
        layer.setLayerVisibility(this.allTrapsDisplayed);
      }
      this.resetDraw();
    }
  }

  resetDraw() {
    this.isManualDrawing = false;
    this.drawingInteraction?.removeFromMap();
    this.drawingInteraction = undefined;
    this.selectionService.activeWidgetStatisticSelection(true);
  }

  onClickDrawingZone() {
    if (this.captureMode === 'MANUAL') {
      if (this.isManualDrawing) {
        this.resetDraw();
      } else {
        this.isManualDrawing = true;
        const freehand = this.drawingMode === 'MANUAL';
        this.drawingInteraction = this.statisticService.drawParcelArea(freehand);
        this.selectionService.activeWidgetStatisticSelection(false);
      }
    } else if (this.captureMode === 'AUTOMATIC') {
      const config: StatisticConfig = {
        display: true,
        filter: new TrapFilter({
          emprise: this.mapService.getMapExtentCoordinatesWkt(),
        }),
      };
      this.statisticService.statisticState.setValue(config);
    }
  }

  resetMultiTrapsConfig() {
    this.captureMode = 'AUTOMATIC';
  }
}
