import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ParameterId, WidgetType } from '@core/model/application-api/widget.model';
import { MapService } from '@core/services/map.service';
import { DateUtils } from '@core/utils/date.utils';
import { DataLayerConfig } from '@feature/client-carto-app/data-layer-config.model';
import { WidgetService } from '@widgets/widgets.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'smv-calendar',
  standalone: true,
  imports: [CommonModule, MatDatepickerModule, MatInputModule, MatFormFieldModule, FormsModule, MatNativeDateModule],
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnDestroy {
  public date?: Date;
  private layers: DataLayerConfig[] = [];

  private subscriptions = new Subscription();

  constructor(
    private mapService: MapService,
    private widgetService: WidgetService
  ) {
    this.subscriptions.add(
      this.widgetService
        .getWidgetContextItem(WidgetType.DATEPICKER)
        .getStream()
        .subscribe((widget) => {
          if (widget) {
            const layersName = widget.parameters.find((parameter) => parameter.id == ParameterId.LAYERNAME)
              ?.value as string[];
            this.layers = layersName.map((code) => this.mapService.getLayerByCode(code));
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateDate() {
    let date;
    if (this.date) {
      date = DateUtils.convertDateInUTC(this.date).toISOString().split('T')[0];
    }
    this.mapService.updateTimeParam(this.layers, date);
  }
}
