import { GeneralUtils } from '@core/utils/general.utils';
import { GeoserverLayersUtils } from '@core/utils/geoserver-layers.utils';
import { CQL } from '@core/utils/parsers/CQL';
import { XmlTag } from '@core/utils/xml.utils';
import { GeoserverVariablesService } from '@feature/client-carto-app/widgets/widgets-filter-cql.service';
import { FeatureLike } from 'ol/Feature';

interface Properties {
  [x: string]: string | number;
}

export class StyleCqlFilter {
  public filterSld?: XmlTag;
  private readonly geoserverVariables;

  constructor() {
    this.geoserverVariables = GeoserverVariablesService.getInstance();
  }

  computeFilter(cql?: string) {
    this.filterSld = new CQL().filterToSld(cql);
  }

  evaluate(feature: FeatureLike, filter?: XmlTag): boolean {
    if (!this.filterSld) {
      return false;
    }
    if (!filter) {
      if (this.filterSld.name == 'Filter') {
        filter = (this.filterSld.content as XmlTag[])[0];
      } else {
        filter = this.filterSld;
      }
    }

    if (
      [
        'PropertyIsLike',
        'PropertyIsEqualTo',
        'PropertyIsLessThan',
        'PropertyIsLessThanOrEqualTo',
        'PropertyIsBetween',
        'PropertyIsGreaterThan',
        'PropertyIsNull',
        'PropertyIsGreaterThanOrEqualTo',
      ].includes(filter.name)
    ) {
      return this.evaluateComparison(feature, filter);
    } else if (['Or', 'And', 'Not'].includes(filter.name)) {
      return this.evaluateLogical(feature, filter) ?? false;
    } else if (filter.name == 'Intersects') {
      throw new Error('Spatial Filter not implemented');
    }
    return false;
  }

  private evaluateExpression(expression: string, attributes: Properties): string | number {
    return attributes[expression.replace(/\{\{|\}\}/g, '')];
  }

  private evaluateVariable(expression: string) {
    if (expression.indexOf('{') >= 0) {
      const variables = this.geoserverVariables?.geoserverVariablesState.getValue();
      if (variables) {
        return GeoserverLayersUtils.computeCqlFilterValue(expression, variables);
      }
    }
    return expression;
  }

  private evaluateComparison(feature: FeatureLike, filter: XmlTag): boolean {
    const propertyName = (filter.content as XmlTag[]).find((tag) => tag.name == 'PropertyName');
    const got = this.evaluateExpression(propertyName?.content as string, feature.getProperties());

    let expression = (filter.content as XmlTag[]).find((tag) => ['Literal'].includes(tag.name))?.content;
    let lowerBoundary = (filter.content as XmlTag[]).find((tag) => ['LowerBoundary'].includes(tag.name))?.content as
      | string
      | number;
    let upperBoundary = (filter.content as XmlTag[]).find((tag) => ['UpperBoundary'].includes(tag.name))?.content as
      | string
      | number;

    expression = this.evaluateVariable(String(expression));
    lowerBoundary = Number(this.evaluateVariable(String(lowerBoundary)));
    upperBoundary = Number(this.evaluateVariable(String(upperBoundary)));

    let regexp;
    let dateExpression;
    switch (filter.name) {
      case 'PropertyIsEqualTo':
        if (typeof got == 'string' && typeof expression == 'string') {
          dateExpression = !isNaN(new Date(got).valueOf())
            ? new Date(got).valueOf() == new Date(expression).valueOf()
            : false;
          return got.toUpperCase() == expression.toUpperCase() || dateExpression;
        } else if (typeof got == 'boolean') {
          const boolExpression = expression === 'true';
          return got === boolExpression;
        } else {
          return got == expression;
        }
      case 'PropertyIsNotEqualTo':
        if (typeof got == 'string' && typeof expression == 'string') {
          return got.toUpperCase() != expression.toUpperCase();
        } else if (typeof got == 'boolean') {
          const boolExpression = expression === 'true';
          return got !== boolExpression;
        } else {
          return got != expression;
        }
      case 'PropertyIsLessThan':
        dateExpression = !isNaN(new Date(got).valueOf())
          ? new Date(got).valueOf() < new Date(expression).valueOf()
          : false;
        return Number(got) < Number(expression) || dateExpression;
      case 'PropertyIsGreaterThan':
        dateExpression = !isNaN(new Date(got).valueOf())
          ? new Date(got).valueOf() > new Date(expression).valueOf()
          : false;
        return Number(got) > Number(expression) || dateExpression;
      case 'PropertyIsLessThanOrEqualTo':
        dateExpression = !isNaN(new Date(got).valueOf())
          ? new Date(got).valueOf() <= new Date(expression).valueOf()
          : false;
        return Number(got) <= Number(expression) || dateExpression;
      case 'PropertyIsGreaterThanOrEqualTo':
        dateExpression = !isNaN(new Date(got).valueOf())
          ? new Date(got).valueOf() >= new Date(expression).valueOf()
          : false;
        return Number(got) >= Number(expression) || dateExpression;
      case 'PropertyIsBetween':
        dateExpression = !isNaN(new Date(got).valueOf())
          ? new Date(got).valueOf() >= new Date(lowerBoundary).valueOf() &&
            new Date(got).valueOf() <= new Date(upperBoundary).valueOf()
          : false;
        return (Number(got) >= lowerBoundary && Number(got) <= upperBoundary) || dateExpression;
      case 'PropertyIsLike':
        regexp = new RegExp(
          GeneralUtils.valueToRegex(
            String(expression),
            filter.attributes?.['wildCard'],
            filter.attributes?.['singleChar'],
            filter.attributes?.['escapeChar']
          ),
          'gi'
        );
        return regexp.test(String(got));
      case 'PropertyIsNull':
        return got === undefined || got === null;
    }
    return false;
  }

  private evaluateLogical(feature: FeatureLike, filter: XmlTag) {
    switch (filter.name) {
      case 'And':
        for (const condition of filter.content as XmlTag[]) {
          if (this.evaluate(feature, condition)) {
            return false;
          }
        }
        return true;

      case 'Or':
        for (const condition of filter.content as XmlTag[]) {
          if (this.evaluate(feature, condition)) {
            return true;
          }
        }
        return false;

      case 'Not':
        return !this.evaluate(feature, (filter.content as XmlTag[])[0]);
    }
    return undefined;
  }
}
