import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FeatureOverlay, LayerProperty } from '@core/model/application-api/layer.model';
import { DataLayerConfig } from '@feature/client-carto-app/data-layer-config.model';

interface TooltipForm {
  active: FormControl<boolean | null>;
  content: FormControl<string | null>;
}

@Component({
  selector: 'smv-layer-tooltip-tab',
  templateUrl: './layer-tooltip-tab.component.html',
  styleUrls: ['./layer-tooltip-tab.component.scss'],
})
export class LayerTooltipTabComponent implements OnInit, OnChanges {
  @Input() layer!: DataLayerConfig;
  @Input() properties?: LayerProperty[];
  @Input() readonly = false;
  @Output() tooltipUpdate = new EventEmitter<FeatureOverlay>();

  public form = new FormGroup<TooltipForm>({
    active: new FormControl<boolean>(false),
    content: new FormControl<string | null>(null),
  });
  public helpText = $localize`:Layer tooltip:Vous pouvez placer les noms des attributs de l'objet entre accolades pour afficher leur valeur lors du survol de l'objet. Par exemple : « Contenu {attr_1} »`;

  ngOnInit() {
    // quill ne ne prend pas en compte les <p>, du coups il faut remplacer par <br>
    this.form.patchValue({
      active: this.layer.config.featureOverlay.active,
      content: this.layer.config.featureOverlay.content?.replaceAll('</p><p>', '<br/>') ?? this.getDefaultContent(),
    });

    if (this.readonly) {
      this.form.disable();
    }

    this.form.valueChanges.subscribe(() => {
      const { active, content } = this.form.value;
      this.tooltipUpdate.emit({ active: active ?? false, content: content ?? undefined });
    });
  }

  ngOnChanges() {
    this.form.patchValue({
      content: this.layer.config.featureOverlay.content ?? this.getDefaultContent(),
    });
  }

  getDefaultContent(): string | null {
    if (this.properties) {
      return this.properties.map((property) => '<p>' + property.name + ':{' + property.name + '}</p>').join('');
    }
    return null;
  }
}
