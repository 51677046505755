import { HttpParams } from '@angular/common/http';
import { OlGmapDefaultConfiguration } from '@syngenta/ol-gmap';
import { Feature, Map, MapBrowserEvent, Tile, VectorTile } from 'ol';
import { Extent } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import MVT from 'ol/format/MVT';
import WFS from 'ol/format/WFS';
import { Projection, transform } from 'ol/proj';
import WMSTileSource from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';
import { Options as VectorTileOptions, default as VectorTileSource } from 'ol/source/VectorTile';
import WMTS, { Options as WMTSOptions, optionsFromCapabilities } from 'ol/source/WMTS';
import TileGrid from 'ol/tilegrid/TileGrid';

import { LayerConfig, WfsLayerConfig, WmsLayerConfig, WmtsLayerConfig } from '@core/model/layer-config.model';
import { DataLayerConfig } from '@feature/client-carto-app/data-layer-config.model';
import { GeoserverRequestVariables } from '@widgets/widgets-filter-cql.service';
import { TileCoord } from 'ol/tilecoord';
import { Indexable } from './general.utils';
import { MapUtils } from './map.utils';

export class GeoserverLayersUtils {
  static getWfsUrl(
    config: WfsLayerConfig,
    extent: Extent,
    projection: string,
    serviceUrl: string,
    cqlFilter: string
  ): string {
    let params = new HttpParams()
      .append('maxfeatures', config.maxFeatures ?? 0)
      .append('count', config.maxFeatures ?? 0)
      .append('typenames', config.geoserverLayerName ?? '')
      .append('outputFormat', config.outputFormat)
      .append('srsname', config.projection ?? projection);

    if (config.useEditionStyle) {
      const order = config.maxFeatures === 1 ? 'D,date_modif D' : 'A';
      params = params.append('sortBy', 'is_modified ' + order);
    }
    let requestParameters: string;
    if (config.cqlFilter) {
      // Magellium layers?
      params = params.append('CQL_FILTER', this.buildComplexBBOXValue(config, extent) + ' AND (' + cqlFilter + ')');
      requestParameters = params.toString();
    } else {
      // Standard WFS requests
      params = params.append('BBOX', extent.join(',') + ',' + projection);
      requestParameters = params.toString();
    }
    return serviceUrl.replaceAll('GetCapabilities', 'GetFeature') + '&' + requestParameters;
  }

  static getWfsLoader(
    config: WfsLayerConfig,
    vectorSource: VectorSource,
    serviceUrl: string,
    format: WFS | GeoJSON,
    cqlFilter: string,
    errorCallback: () => void
  ): (extent: Extent, resolution: number, projection: Projection) => void {
    const login = config.auth?.login;
    const password = config.auth?.password;
    return (extent, _resolution, projection) => {
      const url = GeoserverLayersUtils.getWfsUrl(config, extent, projection.getCode(), serviceUrl, cqlFilter);
      const xhr = new XMLHttpRequest();

      xhr.open('GET', url);
      xhr.setRequestHeader('Authorization', localStorage.getItem('Authorization') ?? '');
      if (login && password) {
        xhr.setRequestHeader('Login', 'Basic ' + window.btoa(login + ':' + password));
      }

      const onError = () => {
        errorCallback();
        console.error('An error occurred while loading WFS layer ' + config.shortName);
        vectorSource.removeLoadedExtent(extent);
      };

      xhr.onerror = onError;
      xhr.onload = () => {
        if (xhr.status === 200) {
          try {
            vectorSource.addFeatures(
              format.readFeatures(xhr.responseText, { featureProjection: OlGmapDefaultConfiguration.mapEpsg })
            );
          } catch (error) {
            onError();
          }
        } else {
          onError();
        }
      };
      xhr.send();
    };
  }

  static getVectorTileUrlFunction(config: WmtsLayerConfig, layerSource: VectorTileSource) {
    return (tileCoord: TileCoord, ratio: number, projection: Projection) => {
      const baseUrl = layerSource.getUrls()?.[0];
      if (!baseUrl || !tileCoord) {
        return undefined;
      }
      const params = new HttpParams()
        .append('layer', config.geoserverLayerName)
        .append('style', config.activeStyle ?? '')
        .append('tilematrixSet', projection.getCode())
        .append('request', 'GetTile')
        .append('format', config.format ?? 'application/vnd.mapbox-vector-tile')
        .append('TileMatrix', projection.getCode().split(':')[1] + ':' + tileCoord[0])
        .append('TileCol', tileCoord[1])
        .append('TileRow', tileCoord[2]);
      return baseUrl + params.toString();
    };
  }

  static getVectorTileLoader(
    config: WmtsLayerConfig,
    layerSource: VectorTileSource,
    errorCallback: () => void
  ): (tile: Tile, url: string) => void {
    const login = config.auth?.login;
    const password = config.auth?.password;
    return (tile: Tile, url: string) => {
      if (tile instanceof VectorTile) {
        tile.setLoader((extent) => {
          const maxUrlLength = 2000;

          const xhr = new XMLHttpRequest();
          xhr.responseType = 'arraybuffer';

          let params = undefined;
          if (url.length < maxUrlLength) {
            xhr.open('GET', url);
          } else {
            const urlArray = url.split('?');
            const baseUrl = urlArray[0];
            params = urlArray[1];
            xhr.open('POST', baseUrl, true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
          }

          if (login && password) {
            xhr.setRequestHeader('Authorization', 'Basic ' + window.btoa(login + ':' + password));
          }

          const onError = () => {
            errorCallback();
            console.error('An error occurred while loading VectorTile layer ' + config.shortName);
          };
          xhr.onerror = onError;

          xhr.onload = () => {
            if (xhr.status === 200) {
              try {
                const format = tile.getFormat();
                const arrayBufferView = new Uint8Array(xhr.response);

                const features = format.readFeatures(arrayBufferView, {
                  extent: extent,
                  featureProjection: OlGmapDefaultConfiguration.mapEpsg,
                }) as Feature[];

                tile.setFeatures(features);
              } catch (error) {
                onError();
              }
            } else {
              onError();
            }
          };
          xhr.send(params);
        });
      }
    };
  }

  static configureWmtsOptions(
    layer: WmtsLayerConfig,
    capabilities: unknown,
    sourceOptions: Indexable<string> = {}
  ): WMTSOptions {
    const options = optionsFromCapabilities(capabilities, {
      layer: layer.geoserverLayerName,
      projection: OlGmapDefaultConfiguration.mapEpsg,
      ...sourceOptions,
    });

    if (!options) {
      throw new Error('Could not get WMTS options from capabilities');
    }

    if (layer.ignoreUrlInCapabilities) {
      const searchMask = new RegExp('request(=|%3D)getCapabilities', 'ig');
      if (options.urls?.length && layer.url) {
        options.urls[0] = layer.url?.replace(searchMask, '');
      }
    }

    options.attributions = layer.getAttributionTemplate();

    return options;
  }

  static configureVectorTileOptions(layer: WmtsLayerConfig, capabilities: unknown): VectorTileOptions {
    const options = this.configureWmtsOptions(layer, capabilities, {
      format: 'application/vnd.mapbox-vector-tile',
    });
    const wmts = new WMTS(options);

    const layerIdProperty = layer.properties?.find((prop) => prop.name.toLowerCase().includes('id'));
    return {
      ...(options as VectorTileOptions),
      format: new MVT({ featureClass: Feature, idProperty: layerIdProperty?.name }),
      tileUrlFunction: wmts.getTileUrlFunction(),
    };
  }

  /**
   * Computes the CQL filter and replace variables with their current value.
   * Example of filter: ((trap_cultivation_code='COL') and (monday_date={TIMELINE_DATE_START}T00:00:00Z))
   *
   * @param filter CQL filter with variables
   * @param variables List of the available variables and their values
   * @returns CQL filter completed with the correct values
   */
  static computeCqlFilterValue(filter: string, variables: GeoserverRequestVariables): string {
    const variableRegex = new RegExp(/\{([^}]*)\}/g);
    let filledFilter = filter;
    filter.match(variableRegex)?.forEach((match) => {
      const name = match.replace(/[{}]/g, '');
      filledFilter = filledFilter.replaceAll(match, variables[name]);
    });
    return filledFilter;
  }

  private static buildComplexBBOXValue(config: LayerConfig, extent: Extent): string {
    const geometryPropertyName = config.properties?.find((prop) => prop.type?.includes('gml:'))?.name ?? 'geom';
    return `BBOX(${geometryPropertyName},${extent.join(',')},'EPSG:3857')`;
  }

  static wmsGetFeatureInfoUrl(
    event: MapBrowserEvent<MouseEvent>,
    layer: DataLayerConfig,
    map: Map
  ): string | undefined {
    const config = layer.config as WmsLayerConfig;
    const source = layer.olLayer?.getSource() as WMSTileSource;
    const wmsOptions = {
      INFO_FORMAT: 'application/json',
      QUERY_LAYERS: config.geoserverLayerName,
      BUFFER: '10',
      FEATURE_COUNT: '100',
      FI_LINE_TOLERANCE: '10',
      EXCEPTIONS: 'XML',
      FI_POINT_TOLERANCE: '25',
      FI_POLYGON_TOLERANCE: '5',
      CQL_FILTER: source.getParams().CQL_FILTER,
      SLD_BODY: undefined,
    };
    return source.getFeatureInfoUrl(
      event.coordinate,
      map.getView().getResolution() as number,
      MapUtils.getMapProjection(map),
      wmsOptions
    );
  }

  static wmtsGetFeatureInfoUrl(
    event: MapBrowserEvent<MouseEvent>,
    layer: DataLayerConfig,
    map: Map
  ): string | undefined {
    const source = layer.olLayer?.getSource() as WMTS;
    const mapProjection = MapUtils.getMapProjection(map);
    const coords = transform(event.coordinate, mapProjection, source.getProjection()?.getCode());
    const tileGrid: TileGrid = source.getTileGrid() as TileGrid;
    const requestedTile = tileGrid.getTileCoordForCoordAndResolution(coords, map.getView().getResolution() as number);
    const tileExtent = tileGrid.getTileCoordExtent(requestedTile);
    const tileResolution = tileGrid.getResolution(requestedTile[0]);

    const x = Math.floor((coords[0] - tileExtent[0]) / tileResolution);
    const y = Math.floor((tileExtent[3] - coords[1]) / tileResolution);

    const params = new HttpParams().append('INFOFORMAT', 'application/json').append('I', x).append('J', y);

    return (
      source.getTileUrlFunction().apply(this, [requestedTile, 1, mapProjection])?.replace('GetTile', 'GetFeatureInfo') +
      params.toString()
    );
  }

  /**
   * Builds the URL for a retrieval of all the values for a property
   * @param config Layer configuration
   * @param propertyName Property to retrieve
   * @param cqlFilter Optional data filter
   * @returns The URL
   */
  static wfsGetPropertyValuesUrl(
    config: WfsLayerConfig,
    propertyName: string,
    cqlFilter?: string,
    sort?: string
  ): string | undefined {
    let params = new HttpParams()
      .append('typenames', config.geoserverLayerName ?? '')
      .append('valueReference', propertyName);

    if (cqlFilter) {
      params = params.append('CQL_FILTER', cqlFilter);
    }
    if (sort) {
      params = params.append('sortBy', sort);
    }

    return (
      config.url
        ?.replaceAll('WMS', 'WFS')
        .replaceAll('GetCapabilities', 'GetPropertyValue')
        .replaceAll('1.1.0', '2.0.0')
        .replaceAll('1.3.0', '2.0.0') +
      '&' +
      params.toString()
    );
  }

  /**
   * Builds the URL for a retrieval of properties values
   * @param config Layer configuration
   * @param propertyName Property to retrieve
   * @param cqlFilter Optional data filter
   * @returns The URL
   */
  static wfsGetFeaturePropertyUrl(
    config: WfsLayerConfig,
    propertyName: string,
    cqlFilter?: string,
    sort?: string
  ): string | undefined {
    let params = new HttpParams()
      .append('maxfeatures', config.maxFeatures ?? 0)
      .append('count', config.maxFeatures ?? 0)
      .append('typenames', config.geoserverLayerName ?? '')
      .append('outputFormat', config.outputFormat)
      .append('propertyName', propertyName);

    if (cqlFilter) {
      params = params.append('CQL_FILTER', cqlFilter);
    }
    if (sort) {
      params = params.append('sortBy', sort);
    }

    return config.url?.replaceAll('GetCapabilities', 'GetFeature') + '&' + params.toString();
  }

  /**
   * Builds the URL for a retrieval of a feature
   * @param config Layer configuration
   * @param featureId Id of the feature to retrieve
   * @returns The URL
   */
  static wfsGetFeatureUrl(config: WfsLayerConfig, featureId: string): string | undefined {
    const params = new HttpParams()
      .append('typenames', config.geoserverLayerName ?? '')
      .append('outputFormat', config.outputFormat)
      .append('featureId', featureId);

    return config.url?.replaceAll('GetCapabilities', 'GetFeature') + '&' + params.toString();
  }

  /**
   * Builds the URL for a retrieval of a feature with a condition on the properties
   * @param config Layer configuration
   * @param cqlFilter Filter on the properties of the feature to retrieve
   * @returns The URL
   */
  static wfsGetFeatureByPropertiesUrl(config: WfsLayerConfig, cqlFilter: string): string | undefined {
    const params = new HttpParams()
      .append('typenames', config.geoserverLayerName ?? '')
      .append('outputFormat', config.outputFormat)
      .append('CQL_FILTER', cqlFilter);

    return config.url?.replaceAll('GetCapabilities', 'GetFeature') + '&' + params.toString();
  }

  /**
   * Builds the URL parameters for a KML export.
   *
   * @param extent Extent applied for the export
   * @param layer Geoserver layer name
   * @param style Style name
   * @returns The serialized HTTP parameters
   */
  static wmsKmlDownloadUrlParameters(extent: Extent, layer: string, style?: string): string {
    const exportSize = 2048;
    let params = new HttpParams()
      .set('request', 'GetMap')
      .set('format', 'application/vnd.google-earth.kml+xml')
      .set('layers', layer)
      .set('height', exportSize)
      .set('width', exportSize)
      .set('transparent', false)
      .set('srs', 'EPSG:3857')
      .set('format_options', 'AUTOFIT:true;KMATTR:true;KMPLACEMARK:false;KMSCORE:40;MODE:download;SUPEROVERLAY:false')
      .set('bbox', extent.join(','));

    if (style) {
      params = params.set('styles', style);
    }

    return params.toString();
  }
}
