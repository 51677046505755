import { Component } from '@angular/core';
import { StepperComponent } from '@components/stepper/stepper.component';
import { StepGroup } from '@components/stepper/stepper.model';
import { FarmInfoComponent } from './farm-info/farm-info.component';

@Component({
  selector: 'smv-champ-propre-stepper',
  standalone: true,
  imports: [StepperComponent, FarmInfoComponent],
  templateUrl: './champ-propre-stepper.component.html',
  styleUrl: './champ-propre-stepper.component.scss',
})
export class ChampPropreStepperComponent {
  public steps: StepGroup[] = [
    {
      groupName: $localize`:Champ Propre|Stepper:Gestion de mon exploitation`,
      steps: [
        {
          icon: 'apartment',
          text: $localize`:Champ Propre|Stepper:Informations de l'exploitation`,
          code: 'INFOS_FARM',
        },
        { icon: 'handyman', text: $localize`:Champ Propre|Stepper:Informations matériel`, code: 'INFOS_MATERIEL' },
        {
          icon: 'map',
          text: $localize`:Champ Propre|Stepper:Sélection des parcelles`,
          code: 'SELECTION_PARCELLES',
          completed: true,
        },
      ],
    },
    {
      groupName: $localize`:Champ Propre|Stepper:Auto-évaluation parcellaire`,
      steps: [
        {
          icon: 'agriculture',
          text: $localize`:Champ Propre|Stepper:Informations à la parcelle`,
          code: 'INFOS_PARCELLES',
          completed: true,
          disabled: true,
        },
        {
          icon: 'grass',
          text: $localize`:Champ Propre|Stepper:Panier adventices`,
          code: 'ADVENTICES',
          completed: true,
          disabled: true,
        },
      ],
    },
    {
      groupName: $localize`:Champ Propre|Stepper:Recommandation de programme`,
      steps: [
        { icon: 'thumb_up', text: $localize`:Champ Propre|Stepper:Météo et planning`, code: 'METEO', disabled: true },
      ],
    },
  ];
  public currentStep = 'INFOS_FARM';
}
