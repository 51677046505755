<div *ngFor="let widget of this.widgetsState">
  <div class="widget-row unselectable">
    <mat-checkbox [(ngModel)]="widget.visible" (change)="updateWidgetVisibility(widget)">
      {{ widget.label }}
    </mat-checkbox>
    <!-- désactivé pour Datepicker jusqu'à fix de ParameterId.LAYERNAME -->
    <mat-icon
      *ngIf="widget.parameters.length > 0 && widget.widgetId !== WidgetType.DATEPICKER"
      (click)="editWidgetParameters.emit(widget)"
    >
      settings
    </mat-icon>
  </div>
</div>

<div class="smv-sticky-bottom-actions">
  <button mat-stroked-button (click)="resetWidgetParameters()">
    <mat-icon>undo</mat-icon><span i18n="Button|reset">Réinitialiser</span>
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="saveWidgetParameters()"
    [ngClass]="{ btnSpinner: saving, loaded: saving }"
  >
    <mat-icon>save</mat-icon><span i18n="Button|save">Enregistrer</span>
  </button>
</div>
