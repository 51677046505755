<lib-ol-gmap [configuration]="mapConfig" (ready)="onMapReady($event)" #olgmap>
  <lib-ol-gmap-toolbar top-left-toolbar>
    @if (isZoomActivated$ | async) {
      <lib-ol-gmap-zoom-buttons />
    }
    <lib-ol-gmap-geolocation-button #geolocation />
    <div class="smv-btn-tools-group">
      @if (currentWidgetVisibility()[WidgetType.MEASURE_LENGTH]) {
        <lib-ol-gmap-measure-button
          [type]="MeasureType.LENGTH"
          [beginMessage]="measureMessage.begin"
          [continueMessage]="measureMessage.continueLength"
          [(active)]="activeMeasure.length"
          (activeChange)="onMeasureActiveChanged(MeasureType.LENGTH)"
          [class]="{ 'smv-btn-active': activeMeasure.length }"
          matTooltip="Mesure de distance"
          matTooltipPosition="right"
          i18n-matTooltip
        />
      }
      @if (currentWidgetVisibility()[WidgetType.MEASURE_AREA]) {
        <lib-ol-gmap-measure-button
          [type]="MeasureType.AREA"
          [beginMessage]="measureMessage.begin"
          [continueMessage]="measureMessage.continueArea"
          [(active)]="activeMeasure.area"
          (activeChange)="onMeasureActiveChanged(MeasureType.AREA)"
          [class]="{ 'smv-btn-active': activeMeasure.area }"
          matTooltip="Mesure de surface"
          matTooltipPosition="right"
          i18n-matTooltip
        />
      }
    </div>
    @if (layerToEdit && (canUserEditLayer() || canUserModifyLayer())) {
      <smv-geometry-edition [layer]="layerToEdit" [canOnlyModify]="!canUserEditLayer()" />
    }
  </lib-ol-gmap-toolbar>
  @if (mapReady) {
    <lib-ol-gmap-toolbar bottom-left-toolbar>
      @if (currentWidgetVisibility()[WidgetType.COORDINATES]) {
        <lib-ol-gmap-cursor-position
          [projection]="position.projection"
          [template]="position.template"
          [digits]="position.digits"
        />
      }
      @if (currentWidgetVisibility()[WidgetType.SCALE]) {
        <lib-ol-gmap-scale />
      }
    </lib-ol-gmap-toolbar>
  }
  @if (panelTogglesVisible()) {
    <lib-ol-gmap-toolbar top-right-toolbar>
      @if (currentWidgetVisibility()[WidgetType.LAYERS_MANAGER]) {
        <lib-ol-gmap-button
          icon="layers"
          (click)="togglePanel('dataLayers')"
          matTooltip="Couches de données"
          matTooltipPosition="before"
          i18n-matTooltip
        />
      }
      @if (isFeatureSelected) {
        <lib-ol-gmap-button
          icon="info_outline"
          (click)="togglePanel('featureDetails')"
          matTooltip="Informations"
          matTooltipPosition="before"
          i18n-matTooltip
        />
      }
      @if (currentWidgetVisibility()[WidgetType.LEGEND] && hasLegendAvailable) {
        <lib-ol-gmap-button
          icon="format_list_bulleted"
          (click)="togglePanel('legend')"
          matTooltip="Légende"
          matTooltipPosition="before"
          i18n-matTooltip
        />
      }
      @if (currentWidgetVisibility()[WidgetType.VIGIEVIROSE]) {
        <lib-ol-gmap-button
          src="assets/widgets/vigieVirose/Vigie_Virose_icon.png"
          (click)="togglePanel('widgetVigieVirose')"
          matTooltip="VigieVirose"
          matTooltipPosition="before"
          i18n-matTooltip
        />
      }
      @if (currentWidgetVisibility()[WidgetType.VIGIEVIROSE_ADMIN]) {
        <lib-ol-gmap-button
          src="assets/widgets/vigieVirose/Vigie_Virose_icon.png"
          (click)="togglePanel('widgetVigieViroseAdmin')"
          matTooltip="VigieVirose Admin"
          matTooltipPosition="before"
          i18n-matTooltip
        />
      }
      @if (currentWidgetVisibility()[WidgetType.ICARE]) {
        <lib-ol-gmap-button
          src="assets/widgets/icare/eudemis.png"
          (click)="togglePanel('widgetIcare')"
          matTooltip="Service Eudémis"
          matTooltipPosition="before"
          i18n-matTooltip
        />
      }
      @if (currentWidgetVisibility()[WidgetType.ICARE_ADMIN]) {
        <lib-ol-gmap-button
          src="assets/widgets/icare/eudemis.png"
          (click)="togglePanel('widgetIcareAdmin')"
          matTooltip="Service Eudémis Admin"
          matTooltipPosition="before"
          i18n-matTooltip
        />
      }
      @if (currentWidgetVisibility()[WidgetType.OPTI_SEMIS]) {
        <lib-ol-gmap-button
          src="assets/widgets/optiSemis/optiSemis.png"
          (click)="togglePanel('widgetOptiSemis')"
          matTooltip="OptiSemis"
          matTooltipPosition="before"
          i18n-matTooltip
        />
      }
      @if (currentWidgetVisibility()[WidgetType.REVOLT]) {
        <lib-ol-gmap-button
          src="assets/images/revolt/register/revolt_logo_small.png"
          (click)="togglePanel('widgetRevolt')"
          matTooltip="Rêvolt"
          matTooltipPosition="before"
          i18n-matTooltip
        />
      }
      @if (currentWidgetVisibility()[WidgetType.INTERRA_SCAN]) {
        <lib-ol-gmap-button
          src="assets/widgets/interraScan/InterraScan_logo.png"
          (click)="togglePanel('widgetInterraScan')"
          matTooltip="InterraScan"
          matTooltipPosition="before"
          i18n-matTooltip
        />
      }
      @if (currentWidgetVisibility()[WidgetType.QUALIH2O]) {
        <lib-ol-gmap-button
          class="qualih2o-tab"
          src="assets/widgets/qualih2o/QualiH2O_icon.png"
          (click)="togglePanel('widgetQualiH2O')"
          matTooltip="Quali'H2O"
          matTooltipPosition="before"
          i18n-matTooltip
        />
      }
      @if (layerToValid && canUserValidateLayer()) {
        <lib-ol-gmap-button
          icon="fact_check"
          (click)="togglePanel('editionValidation')"
          matTooltip="Validation"
          matTooltipPosition="before"
          i18n-matTooltip
        />
      }
      @if (visibleWidgets.length) {
        <lib-ol-gmap-button
          icon="keyboard_control"
          [matMenuTriggerFor]="widgetHideMenu"
          matTooltip="Fonctionnalités"
          matTooltipPosition="before"
          i18n-matTooltip
        />
      }
    </lib-ol-gmap-toolbar>
  }
</lib-ol-gmap>
<div class="widget-toolbar-left">
  @if (currentWidgetVisibility()[WidgetType.DATEPICKER]) {
    <smv-calendar />
  }
  @if (currentWidgetVisibility()[WidgetType.SEARCH]) {
    <smv-search-address />
  }
  @if (globalWidgetsVisibility()[WidgetType.COORDINATES_ZOOM]) {
    <smv-coordinates-zoom [hidden]="!localWidgetsVisibility()[WidgetType.COORDINATES_ZOOM]" />
  }
</div>
@if (globalWidgetsVisibility()[WidgetType.TIMELINE]) {
  <smv-timeline [appId]="application().id" [hidden]="!localWidgetsVisibility()[WidgetType.TIMELINE]" />
}
@if (globalWidgetsVisibility()[WidgetType.VIGIEVIROSE]) {
  <smv-vigie-virose
    [applicationId]="application().id"
    [panelHide]="!sidePanels.widgetVigieVirose"
    (displayed)="togglePanel('widgetVigieVirose')"
  />
}
@if (globalWidgetsVisibility()[WidgetType.VIGIEVIROSE_ADMIN]) {
  <smv-vigie-virose-admin
    [applicationId]="application().id"
    [panelHide]="!sidePanels.widgetVigieViroseAdmin"
    [canImportDatum]="isUserApplicationAdmin()"
    [canEditTrap]="canUserEditLayer()"
    (displayed)="togglePanel('widgetVigieViroseAdmin')"
  />
}
@if (globalWidgetsVisibility()[WidgetType.ICARE]) {
  <smv-icare
    [applicationId]="application().id"
    [canImport]="isUserApplicationAdmin()"
    [panelHide]="!sidePanels.widgetIcare"
    (displayed)="togglePanel('widgetIcare')"
  />
}
@if (globalWidgetsVisibility()[WidgetType.ICARE_ADMIN]) {
  <smv-icare-admin
    [applicationId]="application().id"
    [canImport]="isUserApplicationAdmin()"
    [panelHide]="!sidePanels.widgetIcareAdmin"
    (displayed)="togglePanel('widgetIcareAdmin')"
  />
}
@if (globalWidgetsVisibility()[WidgetType.QUALIH2O]) {
  <smv-quali-h2o [panelHidden]="!sidePanels.widgetQualiH2O" (closePanel)="togglePanel('widgetQualiH2O')" />
}
@if (globalWidgetsVisibility()[WidgetType.OPTI_SEMIS]) {
  <smv-opti-semis
    [applicationId]="application().id"
    [canImport]="isUserApplicationAdmin()"
    [panelHide]="!sidePanels.widgetOptiSemis"
    (displayed)="togglePanel('widgetOptiSemis')"
  />
}
@if (globalWidgetsVisibility()[WidgetType.REVOLT]) {
  <smv-revolt
    [applicationId]="application().id"
    [panelHide]="!sidePanels.widgetRevolt"
    (displayed)="togglePanel('widgetRevolt')"
  />
}
@if (globalWidgetsVisibility()[WidgetType.INTERRA_SCAN]) {
  <smv-interra-scan
    [applicationId]="application().id"
    [canEdit]="canUserEditLayer()"
    [panelHide]="!sidePanels.widgetInterraScan"
    (displayed)="togglePanel('widgetInterraScan')"
  />
}
@if (layerToValid && canUserValidateLayer()) {
  <smv-edition-validation
    [layer]="layerToValid"
    [panelHide]="!sidePanels.editionValidation"
    (displayed)="togglePanel('editionValidation')"
  />
}
@if (sidePanels.dataLayerEditor || displayOverlay) {
  <div class="overlay"></div>
}
@if (application()) {
  @if (isUserApplicationAdmin() && sidePanels.settings && configuration && layerGroups) {
    <smv-application-settings
      [application]="application()"
      [configuration]="configuration"
      [layerGroups]="layerGroups"
    />
  }
  @if (sidePanels.dataLayers) {
    <mat-card class="side-panel">
      <mat-card-title>
        <div>
          <span i18n="Application panel|Title">Couches de données</span>
          <button mat-icon-button (click)="togglePanel('dataLayers')">
            <mat-icon>keyboard_double_arrow_right</mat-icon>
          </button>
        </div>
      </mat-card-title>
      <mat-card-content>
        <smv-data-layers
          [layerGroups]="layerGroups"
          [canUserToggleEditionMode]="canUserModifyLayer()"
          (toggleSatelliteView)="olgmap.toggleSatelliteView($event)"
          (viewLayerProperties)="displayLayerProperties($event)"
          (toggleEditionMode)="updateLayerEditionMode($event)"
        />
      </mat-card-content>
      @if (isUserApplicationAdmin()) {
        <mat-card-actions class="layer-editor-actions">
          <button mat-flat-button color="primary" (click)="saveDisplay()" [ngClass]="{ btnSpinner: isSavingDisplay }">
            <mat-icon>save</mat-icon>
            <ng-container i18n="Button|Layer manager action">Enregistrer l'affichage</ng-container>
          </button>
          <button
            mat-flat-button
            color="primary"
            (click)="togglePanel('dataLayerEditor')"
            i18n="Button|Layer manager action"
          >
            Ajouter ou modifier des couches
          </button>
        </mat-card-actions>
      }
    </mat-card>
  }
  @if (sidePanels.layerPropertyViewer) {
    <mat-card cdkScrollable class="side-panel">
      <mat-card-title>
        <div>
          <span i18n="Application panel|Title">Propriétés de la couche</span>
          <button mat-icon-button (click)="togglePanel('layerPropertyViewer')">
            <mat-icon>keyboard_double_arrow_right</mat-icon>
          </button>
        </div>
      </mat-card-title>
      <mat-card-content>
        <smv-layer-properties [layer]="activeLayer" (closePanel)="togglePanel('dataLayers')" [readonly]="true" />
      </mat-card-content>
    </mat-card>
  }
  @if (sidePanels.dataLayerEditor) {
    <smv-data-layer-edition-panel
      [layerGroups]="layerGroups"
      [(application)]="application"
      (layersUpdate)="updateLayers($event)"
      (closePanel)="togglePanel('dataLayers')"
    />
  }
  @if (sidePanels.featureDetails) {
    <mat-card class="side-panel">
      <mat-card-title>
        <div>
          <span i18n="Application panel|Title">Informations</span>
          <button mat-icon-button (click)="togglePanel('featureDetails')">
            <mat-icon>keyboard_double_arrow_right</mat-icon>
          </button>
        </div>
      </mat-card-title>
      <mat-card-content>
        <smv-feature-details [canEdit]="canUserModifyLayer()" />
      </mat-card-content>
    </mat-card>
  }
  @if (sidePanels.legend) {
    <mat-card class="side-panel">
      <mat-card-title>
        <div>
          <span i18n="Application panel|Title">Légende</span>
          <button mat-icon-button (click)="togglePanel('legend')">
            <mat-icon>keyboard_double_arrow_right</mat-icon>
          </button>
        </div>
      </mat-card-title>
      <mat-card-content>
        <smv-legend></smv-legend>
      </mat-card-content>
    </mat-card>
  }
}

<mat-menu #widgetHideMenu>
  @for (widget of visibleWidgets; track widget) {
    <button mat-menu-item (click)="$event.stopPropagation(); toggleWidgetLocalVisibility(widget.widgetId)">
      @if (localWidgetsVisibility()[widget.widgetId]) {
        <mat-icon>visibility_on</mat-icon>
      } @else {
        <mat-icon>visibility_off</mat-icon>
      }
      {{ widget.label }}
    </button>
  }
</mat-menu>
