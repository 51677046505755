import {
  AttributeType,
  Layer,
  LayerGroup,
  LayerProperty,
  LayerPropertyConfiguration,
} from '@core/model/application-api/layer.model';
import { LayerGroupConfig } from '@core/model/layer-config.model';
import { DataLayerGroupConfig } from '@feature/client-carto-app/data-layer-config.model';

export interface AttributesConfig {
  type: AttributeType;
  label?: string;
  editable?: boolean;
  display?: boolean;
  nillable?: boolean;
  configuration?: LayerPropertyConfiguration;
}

export function isLayerGroup(value: object): value is LayerGroup {
  return Object.hasOwn(value, 'childs');
}

export function parseLayers(collection: (Layer | LayerGroup)[]): LayerGroupConfig[] {
  const groups: LayerGroupConfig[] = [];
  const ungroupedLayers: LayerGroup = { categorie: $localize`(Non groupé)`, visible: true, childs: [] };

  collection.forEach((element) => {
    if (isLayerGroup(element)) {
      groups.push(new LayerGroupConfig(element));
    } else {
      ungroupedLayers.childs.push(element);
    }
  });

  const ungroupedLayersConfig = new LayerGroupConfig(ungroupedLayers);
  ungroupedLayersConfig.isUngrouped = true;
  groups.unshift(ungroupedLayersConfig);

  return groups;
}

export function rebuildZIndexes(groups: DataLayerGroupConfig[]): void {
  let layerCount = groups.reduce(
    (accumulator, currentGroup) => accumulator + currentGroup.config.getSublayerCount(),
    0
  );
  groups.forEach((group) => {
    layerCount = group.rebuildIndexes(layerCount);
  });
}

export function buildLayerAttributes(attributes: Map<string, AttributesConfig>): LayerProperty[] {
  const properties: LayerProperty[] = [];
  attributes.forEach((attr, name) => {
    properties.push({
      name,
      label: attr.label,
      type: attr.type,
      editable: attr.editable ?? true,
      display: attr.display ?? true,
      nillable: attr.nillable ?? true,
      configuration: attr.configuration,
    });
  });
  return properties;
}
