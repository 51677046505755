<mat-expansion-panel expanded>
  <mat-expansion-panel-header i18n>Filtre clients</mat-expansion-panel-header>
  <smv-multi-select
    [label]="label"
    [placeholder]="placeholder"
    [hint]="hint"
    identifier="name"
    [displayRow]="displayCustomer"
    [displayRowEm]="displayCustomerEm"
    [searchCondition]="searchCondition"
    [serverFilterFonction]="filter"
    [(selected)]="selectedCustomers"
    (selectedChange)="onSelectionChange()"
  />
</mat-expansion-panel>
