<mat-expansion-panel expanded>
  <mat-expansion-panel-header i18n="OptiSemis|Filter">Contraintes</mat-expansion-panel-header>
  <div class="smv-flex-col">
    @for (constraint of constraints; track constraint.code) {
      <mat-checkbox color="accent" [(ngModel)]="constraint.selected" (change)="computeFilter()">
        {{ constraint.code }}
      </mat-checkbox>
    }
  </div>
  <mat-checkbox color="accent" [(ngModel)]="noConstraint" (change)="computeFilter()" i18n="OptiSemis|Filter">
    Sans contrainte
  </mat-checkbox>
</mat-expansion-panel>
