import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FormFieldWrapperComponent } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { ApplicationSettingsComponent } from './settings.component';
import { SpecificationComponent } from './specification/specification.component';
import { WidgetParametersComponent } from './widgets-management/widget-parameters/widget-parameters.component';
import { WidgetsManagementComponent } from './widgets-management/widgets-management.component';
import { ExtentComponent } from './extent/extent.component';

@NgModule({
  declarations: [
    ApplicationSettingsComponent,
    ExtentComponent,
    WidgetsManagementComponent,
    WidgetParametersComponent,
    SpecificationComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    FormFieldWrapperComponent,
  ],
  exports: [ApplicationSettingsComponent],
})
export class ApplicationSettingsModule {}
