import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DropdownOption } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { ApplicationConfig, SpecificationConfig } from '@core/model/application.model';
import { DataLayerConfig, DataLayerGroupConfig } from '@feature/client-carto-app/data-layer-config.model';

interface SpecForm {
  layer: FormControl<DataLayerConfig | null>;
  attribute: FormControl<string | null>;
  labelPopup: FormControl<string | null>;
  labelButtonHeader: FormControl<string | null>;
  hideLayers: FormControl<boolean>;
  restrictToKnown: FormControl<boolean>;
}

@Component({
  selector: 'smv-specification',
  templateUrl: './specification.component.html',
  styleUrls: ['./specification.component.scss'],
})
export class SpecificationComponent implements OnInit {
  @Input() configuration?: ApplicationConfig;
  @Input() layerGroups?: DataLayerGroupConfig[];
  @Input() saving = false;

  @Output() specConfigUpdate = new EventEmitter<SpecificationConfig>();

  public paramSpec = new FormGroup<SpecForm>({
    layer: new FormControl<DataLayerConfig | null>(null),
    attribute: new FormControl<string | null>(null),
    labelPopup: new FormControl<string | null>(null),
    labelButtonHeader: new FormControl<string | null>(null),
    hideLayers: new FormControl<boolean>(false, { nonNullable: true }),
    restrictToKnown: new FormControl<boolean>(true, { nonNullable: true }),
  });

  public attributes: DropdownOption<string>[] = [];
  public layerOptions: DropdownOption<DataLayerConfig>[] = [];
  private layers: DataLayerConfig[] = [];

  ngOnInit(): void {
    if (this.layerGroups) {
      this.flattenLayers(this.layerGroups);
      this.layerOptions = this.layers.map((layer): DropdownOption<DataLayerConfig> => {
        return {
          label: layer.config.shortName ?? '',
          value: layer,
        };
      });
    }
    this.resetConfig();

    this.paramSpec.controls.layer.valueChanges.subscribe((selectedLayer) =>
      this.computeAttributesOption(selectedLayer)
    );
  }

  resetConfig() {
    this.paramSpec.reset();
    if (this.configuration && this.configuration.specification) {
      const layerCode = this.configuration.specification.layerCode;
      const configLayer = this.layers.find((layer) => layer.config.code === layerCode);
      if (configLayer) {
        this.computeAttributesOption(configLayer);
        this.paramSpec.patchValue({
          layer: configLayer,
          attribute: this.configuration.specification.attributeName,
          labelPopup: this.configuration.specification.labelPopup,
          labelButtonHeader: this.configuration.specification.labelButtonHeader,
          hideLayers: this.configuration.specification.hideLayers,
          restrictToKnown: this.configuration.specification.restrictToKnown,
        });
      }
    }
  }

  saveConfig() {
    const { layer, attribute, labelPopup, labelButtonHeader, hideLayers, restrictToKnown } =
      this.paramSpec.getRawValue();
    const newConfig: SpecificationConfig = layer
      ? {
          layerCode: layer.config.code,
          attributeName: attribute ?? undefined,
          labelPopup: labelPopup ?? undefined,
          labelButtonHeader: labelButtonHeader ?? undefined,
          hideLayers: hideLayers,
          restrictToKnown: restrictToKnown,
        }
      : {
          hideLayers: hideLayers,
          restrictToKnown: restrictToKnown,
        };
    this.specConfigUpdate.emit(newConfig);
  }

  private flattenLayers(layerGroups: DataLayerGroupConfig[]) {
    layerGroups.forEach((layerGroup) => {
      this.layers.push(...layerGroup.layers.filter((layer) => !layer.config.baseLayer && layer.config.editable));
      if (layerGroup.subgroups) {
        this.flattenLayers(layerGroup.subgroups);
      }
    });
  }

  private computeAttributesOption(layer: DataLayerConfig | null) {
    if (layer && layer.config.properties) {
      this.paramSpec.controls.attribute.reset();
      this.attributes = layer.config.properties.map((property) => {
        return {
          label: property.label ?? property.name,
          value: property.name,
        };
      });
    } else {
      this.attributes = [];
    }
  }
}
