import { formatDate } from '@angular/common';
import { Periodicity } from '@feature/client-carto-app/widgets/timeline/timeline.model';

export class DateUtils {
  static stringToDate(expr: string, periodicity: Periodicity): Date {
    if (expr.indexOf('now') == 0) {
      const toAdd = Number(expr.substring(3).trim());
      const date = new Date();
      if (toAdd) {
        switch (periodicity) {
          case Periodicity.DAYS:
            return DateUtils.addDays(date, toAdd);
          case Periodicity.WEEKS:
            return DateUtils.addDays(date, toAdd * 7);
          case Periodicity.MONTHS:
            return DateUtils.addDays(date, toAdd * 31);
          case Periodicity.YEARS:
            return DateUtils.addDays(date, toAdd * 365);
        }
      } else {
        return date;
      }
    } else {
      return new Date(expr);
    }
  }

  static getDates(startDate: Date, endDate: Date, periodicity: Periodicity): Date[] {
    const dates = [];
    let daysToAdd = 1;
    if (periodicity == Periodicity.WEEKS) {
      daysToAdd = 7;
      startDate = DateUtils.getFirstDayOfWeek(startDate);
      endDate = DateUtils.getFirstDayOfWeek(endDate);
    }
    if (periodicity == Periodicity.MONTHS) {
      daysToAdd = 31;
      startDate = DateUtils.getFirstDayOfMonth(startDate);
      endDate = DateUtils.getFirstDayOfMonth(endDate);
    }
    if (periodicity == Periodicity.YEARS) {
      daysToAdd = 365;
      startDate = DateUtils.getFirstDayOfYear(startDate);
      endDate = DateUtils.getFirstDayOfYear(endDate);
    }
    let currentDate = startDate;
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = DateUtils.addDays(currentDate, daysToAdd);
    }
    return dates;
  }

  static getFirstDayOfMonth(date: Date | string): Date {
    const asDate = new Date(date);
    asDate.setDate(1);
    return asDate;
  }

  static getUTCFirstDayOfMonth(date: Date | string): Date {
    const asDate = new Date(date);
    asDate.setDate(1);
    return DateUtils.convertDateInUTC(DateUtils.getFirstDayOfMonth(date));
  }

  static getLastDayOfMonth(date: Date | string): Date {
    const asDate = new Date(date);
    asDate.setDate(31);
    return asDate;
  }

  static getUTCLastDayOfMonth(date: Date | string): Date {
    const asDate = new Date(date);
    asDate.setDate(31);
    return DateUtils.convertDateInUTC(DateUtils.getLastDayOfMonth(date));
  }

  static getFirstDayOfYear(date: Date | string): Date {
    const asDate = new Date(date);
    asDate.setDate(1);
    asDate.setMonth(0);
    return asDate;
  }

  static getUTCFirstDayOfYear(date: Date | string): Date {
    return DateUtils.convertDateInUTC(DateUtils.getFirstDayOfYear(date));
  }

  static getLastDayOfYear(date: Date | string): Date {
    const asDate = new Date(date);
    asDate.setDate(31);
    asDate.setMonth(11);
    return asDate;
  }

  static getUTCLastDayOfYear(date: Date | string): Date {
    return DateUtils.convertDateInUTC(DateUtils.getLastDayOfYear(date));
  }

  static getFirstDayOfWeek(date: Date | string): Date {
    const asDate = new Date(date);
    return this.addDays(asDate, 1 - asDate.getDay());
  }

  static getUTCFirstDayOfWeek(date: Date | string): Date {
    return DateUtils.convertDateInUTC(DateUtils.getFirstDayOfWeek(date));
  }

  static getLastDayOfWeek(date: Date | string): Date {
    const asDate = new Date(date);
    return this.addDays(asDate, 7 - asDate.getDay());
  }

  static getUTCLastDayOfWeek(date: Date | string): Date {
    return DateUtils.convertDateInUTC(DateUtils.getLastDayOfWeek(date));
  }

  static addDays(date: Date | string, days: number): Date {
    const asDate = new Date(date);
    asDate.setDate(asDate.getDate() + days);
    return asDate;
  }

  static convertDateInUTC(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }

  static dateToString(date: Date): string {
    return date.toISOString().substring(0, 10);
  }

  static getDateForEdition(): string {
    return formatDate(new Date(), 'yyyy-MM-ddThh:mm:ss', 'en');
  }

  static getDateForGraphic(date: Date): string {
    // TODO: récupérer l'info depuis i18n
    return new Date(date).toLocaleString('fr-FR').split(' ')[0].split(',')[0];
  }

  static getWeekNumber(date: Date) {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil(((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
    return weekNo;
  }
}
