import { Component } from '@angular/core';
import { ChampPropreStepperComponent } from '@feature/champ-propre/champ-propre-stepper/champ-propre-stepper.component';

@Component({
  selector: 'smv-client-standard-app',
  standalone: true,
  imports: [ChampPropreStepperComponent],
  templateUrl: './client-standard-app.component.html',
  styleUrl: './client-standard-app.component.scss',
})
export class ClientStandardAppComponent {}
