import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { LayerType } from '@core/model/application-api/layer.model';
import { WmsLayerConfig } from '@core/model/layer-config.model';
import { DataLayerConfig } from '@feature/client-carto-app/data-layer-config.model';

export interface LegendUpdate {
  display: boolean;
  url?: string;
}

@Component({
  selector: 'smv-layer-legend-tab',
  templateUrl: './layer-legend-tab.component.html',
  styleUrls: ['./layer-legend-tab.component.scss'],
})
export class LayerLegendTabComponent implements OnInit {
  @Input() layer!: DataLayerConfig;
  @Input() readonly = false;

  @Output() legendUpdate = new EventEmitter<LegendUpdate>();

  public isWms = false;
  public clonedLayer?: DataLayerConfig;
  public legendDisplayControl = new FormControl<boolean>(false);
  public legendUrlControl = new FormControl<string | undefined>(undefined);

  ngOnInit() {
    this.clonedLayer = this.layer.clone();
    this.legendDisplayControl.patchValue(this.layer.config.isLegendDisplayed ?? false);

    if (this.readonly) {
      this.legendDisplayControl.disable();
    }
    this.isWms = this.layer.config.type === LayerType.WMS;
    if (this.isWms) {
      this.legendUrlControl.patchValue((this.layer.config as WmsLayerConfig).legendUrl);
    }

    new FormArray([this.legendDisplayControl, this.legendUrlControl]).valueChanges.subscribe(() => {
      this.legendUpdate.emit({
        display: this.legendDisplayControl.value ?? false,
        url: this.legendUrlControl.value ?? undefined,
      });
    });
  }

  reloadPreview() {
    if (this.clonedLayer) {
      (this.clonedLayer.config as WmsLayerConfig).legendUrl = this.legendUrlControl.value ?? undefined;
      this.clonedLayer = this.clonedLayer?.clone();
    }
  }
}
