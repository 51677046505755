import { BooleanInput } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  Injector,
  Input,
  OnChanges,
} from '@angular/core';
import { NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ErrorMessages } from '@components/form-field-errors/form-error-messages';
import { ControlValueAccessorImpl } from '@core/form/control-value-accessor.impl';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormFieldWrapperComponent } from '../form-field-wrapper/form-field-wrapper.component';

const modules = [ColorPickerModule, CommonModule, FormFieldWrapperComponent, MatButtonModule, MatIconModule];

@Component({
  selector: 'smv-color-picker-field',
  standalone: true,
  imports: modules,
  templateUrl: './color-picker-field.component.html',
  styleUrls: ['./color-picker-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerFieldComponent),
      multi: true,
    },
  ],
})
export class ColorPickerFieldComponent extends ControlValueAccessorImpl<string> implements AfterViewInit, OnChanges {
  @Input() hideField: BooleanInput = false;

  public errorMessages: ErrorMessages = {
    notAColor: $localize`Le format de la couleur est invalide. Les formats acceptés sont le hex, le rgba et le rgb.`,
  };

  constructor(
    @Inject(Injector) private injector: Injector,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.registerFormControl(this.injector.get(NgControl));
    this.changeDetector.detectChanges();
  }

  ngOnChanges(): void {
    this.coerceAll(['hideField']);
  }

  updateColor(value: string): void {
    this.control?.setValue(value);
    super.writeValue(value);
  }
}
