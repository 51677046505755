import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { WfsLayerConfig } from '@core/model/layer-config.model';
import { EditionService } from '@core/services/edition.service';
import { MapService } from '@core/services/map.service';
import { NotificationService } from '@core/services/notification.service';
import { SelectionService } from '@core/services/selection.service';
import { GeneralUtils } from '@core/utils/general.utils';
import { GeoserverLayersUtils } from '@core/utils/geoserver-layers.utils';
import { MapUtils } from '@core/utils/map.utils';
import { GeoserverVariablesService } from '@widgets/widgets-filter-cql.service';
import { DataLayerConfig } from '../data-layer-config.model';
import { EditionCommentService } from './edition-comment.service';
import {
  ButtonType,
  ValidationLabelOptions,
  ValidationOptionsGrouped,
  ValidationOptionsNotify,
} from './edition-validation.model';
import { EditionValidationService } from './edition-validation.service';
import { NotifyFeedbackDialogComponent } from './notify-feedback-dialog/notify-feedback-dialog.component';
import { ISNotification, NotificationValidationConfig, NotificationValidations } from './notify-validation.model';
import { NotifyValidationService } from './notify-validation.services';

import { Feature } from 'ol';
import GeoJSONFormat from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import { Subscription, finalize, forkJoin, map, of } from 'rxjs';

interface LinkedEntitiesStat {
  all: number[];
  duplicates: number[];
}

@Component({
  selector: 'smv-edition-validation',
  templateUrl: './edition-validation.component.html',
  styleUrls: ['./edition-validation.component.scss', '../client-carto-app-panel.scss'],
})
export class EditionValidationComponent implements OnInit, OnDestroy {
  @Input() layer!: DataLayerConfig;
  @Input() panelHide = true;
  @Output() displayed = new EventEmitter();

  public validationView = new FormControl<boolean>(false, { nonNullable: true });
  public layerConfig?: WfsLayerConfig;
  public displayEditedFeatures?: Feature[];
  public displayEditedGeometries?: Geometry[];
  public linkedEntities?: LinkedEntitiesStat;
  public filteredLinkedEntities?: LinkedEntitiesStat;

  public editedFeatures: Feature[] = [];
  public originalFeatures: Map<number, Feature> = new Map();
  public warnings = 0;

  public selectedFeature?: Feature;
  public isSaving = false;
  public loading = false;

  public optionsGrouped = ValidationOptionsGrouped;
  public optionsNotify = ValidationOptionsNotify;
  public groupedOption = new FormControl<boolean>(false);
  public notifyOption = new FormControl<boolean>(false);
  public notifyConfig: NotificationValidationConfig = NotificationValidations.NONE;

  public labelRejectButton = '';
  public labelValidButton = '';

  private readonly GROUP_OPTION_KEY = 'smv-validation-group-option';
  private readonly NOTIFY_OPTION_KEY = 'smv-validation-notify-option';

  private index = 0;
  private featuresInWarning: number[] = [];
  private subscriptions = new Subscription();

  constructor(
    private geoserverVariables: GeoserverVariablesService,
    private validationService: EditionValidationService,
    private selectionService: SelectionService,
    private editionService: EditionService,
    private editionCommentService: EditionCommentService,
    private mapService: MapService,
    private notifyValidationService: NotifyValidationService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private changeDetector: ChangeDetectorRef
  ) {
    this.validationView.valueChanges.subscribe((value) => {
      this.geoserverVariables.editionValidationState.setParticularValue('validation', value);
      if (value) {
        this.editionService.resetEdition(true);
        this.selectionService.selectedFeatures.reset();
      }
    });
    this.subscriptions.add(
      this.geoserverVariables.editionValidationState.getStream().subscribe((state) => {
        if (state.validation) {
          this.getToValidateFeatures(this.linkedEntities === undefined);
        }
      })
    );
    this.subscriptions.add(
      this.validationService.resetValidationFilters.subscribe(() => this.getToValidateFeatures(true))
    );
    this.subscriptions.add(
      this.groupedOption.valueChanges.subscribe((value) => {
        if (this.groupedOption.enabled) {
          localStorage.setItem(this.GROUP_OPTION_KEY, `${value}`);
        }
        this.setButtonLabels();
      })
    );
    this.subscriptions.add(
      this.notifyOption.valueChanges.subscribe((value) => {
        if (this.notifyOption.enabled) {
          localStorage.setItem(this.NOTIFY_OPTION_KEY, `${value}`);
        }
        this.setButtonLabels();
      })
    );
  }

  ngOnInit(): void {
    if (this.layer.config instanceof WfsLayerConfig) {
      this.layerConfig = this.layer.config;
      this.setValidationOptions(this.layerConfig);
    }
  }

  ngOnDestroy(): void {
    this.geoserverVariables.editionValidationState.reset();
    this.subscriptions.unsubscribe();
  }

  private getToValidateFeatures(reset = false) {
    if (this.layerConfig) {
      const url = GeoserverLayersUtils.wfsGetFeatureByPropertiesUrl(
        this.layerConfig,
        this.geoserverVariables.geoserverVariablesState.getParticularValue('EDITION_VALIDATION')
      );
      if (url) {
        this.loading = true;
        this.validationService
          .getFeaturesFromGeoserver(url)
          .pipe(finalize(() => (this.loading = false)))
          .subscribe((features) => {
            this.index = 0;
            if (this.layer && features.length) {
              this.displayEditedFeatures = features.map((feature) => new GeoJSONFormat().readFeature(feature));
              this.displayEditedGeometries = this.displayEditedFeatures
                .map((olFeature) => olFeature.getGeometry())
                .filter(GeneralUtils.isNotNull);
              this.getDuplicates(reset);
              this.getWarningCount();
              if (reset) {
                this.retrieveOriginals();
              }
            } else {
              this.displayEditedFeatures = undefined;
              this.displayEditedGeometries = undefined;
              this.filteredLinkedEntities = undefined;
            }
          });
      }
    }
  }

  private getDuplicates(reset = false) {
    if (this.displayEditedFeatures) {
      this.filteredLinkedEntities = {
        all: [],
        duplicates: [],
      };
      this.displayEditedFeatures.forEach((feature) => {
        const currentValue = feature.get('linked_entity');
        if (currentValue) {
          const hasDuplicate = this.filteredLinkedEntities?.all.some((ele) => ele === currentValue);
          if (hasDuplicate) {
            const alreadyDuplicates = this.filteredLinkedEntities?.duplicates.some((ele) => ele === currentValue);
            if (!alreadyDuplicates) {
              this.filteredLinkedEntities?.duplicates.push(currentValue);
            }
          } else {
            this.filteredLinkedEntities?.all.push(currentValue);
          }
        }
      });
      if (reset) {
        this.linkedEntities = {
          all: Array.from(this.filteredLinkedEntities?.all),
          duplicates: Array.from(this.filteredLinkedEntities?.duplicates),
        };
      }
    }
  }

  private retrieveOriginals() {
    if (this.displayEditedFeatures) {
      this.editedFeatures = this.displayEditedFeatures;
      const newOriginals = new Map();
      this.getOriginalFeatures(this.displayEditedFeatures, false).subscribe((originalFeatures) => {
        for (const originalFeature of originalFeatures) {
          const id = MapUtils.getFeatureId(originalFeature);
          newOriginals.set(id, originalFeature);
        }
        this.originalFeatures = newOriginals;
        this.changeDetector.detectChanges();
      });
    }
  }

  private getOriginalFeatures(features: Feature[], fillNull = true) {
    const calls = [];
    if (this.layerConfig) {
      for (const feature of features) {
        const id = feature.get('linked_entity');
        if (id) {
          const url = GeoserverLayersUtils.wfsGetFeatureUrl(this.layerConfig, id);
          if (url) {
            calls.push(
              this.validationService.getFeaturesFromGeoserver(url).pipe(
                map((originalFeature) => {
                  if (originalFeature.length) {
                    const retrievedFeature = new GeoJSONFormat().readFeatures(originalFeature[0]);
                    return retrievedFeature.length ? retrievedFeature[0] : feature;
                  } else {
                    return feature;
                  }
                })
              )
            );
          }
        } else {
          if (fillNull) {
            calls.push(of(feature));
          }
        }
      }
    }
    return forkJoin(calls);
  }

  zoomToEditedFeature() {
    if (this.layerConfig && this.displayEditedGeometries?.length) {
      this.index = (this.index + 1) % this.displayEditedGeometries.length;
      this.mapService.fitToLayer(
        this.layerConfig,
        this.displayEditedGeometries[this.index].getExtent(),
        this.layerConfig.projection ?? MapUtils.PROJECTION_MAP?.getCode()
      );
    }
  }

  handleWarnings(featuresWarnings: number[]) {
    this.featuresInWarning = featuresWarnings;
    this.getWarningCount();
  }

  private getWarningCount() {
    const displayedFeatureIds = this.displayEditedFeatures?.map(MapUtils.getFeatureId);
    this.warnings = this.featuresInWarning.filter((featureId) => displayedFeatureIds?.includes(featureId)).length;
  }

  private setValidationOptions(layerConfig: WfsLayerConfig) {
    let grouped = localStorage.getItem(this.GROUP_OPTION_KEY) === 'true';
    let notify = localStorage.getItem(this.NOTIFY_OPTION_KEY) === 'true';

    if (layerConfig.validationConfiguration) {
      if (!layerConfig.validationConfiguration.grouped) {
        this.groupedOption.disable();
        grouped = false;
      }
      if (layerConfig.validationConfiguration.notify === 'NONE') {
        this.notifyOption.disable();
        notify = false;
      } else {
        if (layerConfig.validationConfiguration.notifyForced) {
          this.notifyOption.disable();
          notify = true;
        }
        this.notifyConfig = NotificationValidations[layerConfig.validationConfiguration.notify];
      }
    }

    this.groupedOption.setValue(grouped);
    this.notifyOption.setValue(notify);
  }

  private setButtonLabels() {
    if (this.groupedOption.value) {
      this.labelRejectButton = ValidationLabelOptions.reject.grouped;
      this.labelValidButton = ValidationLabelOptions.valid.grouped;
    } else {
      this.labelRejectButton = ValidationLabelOptions.reject.notGrouped;
      this.labelValidButton = ValidationLabelOptions.valid.notGrouped;
    }
  }

  disableTooltip() {
    return (
      !this.groupedOption.value ||
      !this.displayEditedFeatures?.length ||
      this.filteredLinkedEntities?.duplicates?.length == 0
    );
  }

  disableButton(type: ButtonType) {
    if (!this.groupedOption.value) {
      return !this.selectedFeature;
    }
    if (type === 'REJECT') {
      return !this.displayEditedFeatures?.length;
    } else {
      return !this.displayEditedFeatures?.length || this.filteredLinkedEntities?.duplicates?.length != 0;
    }
  }

  public saveDecision(action: ButtonType) {
    if (this.layerConfig) {
      this.isSaving = true;
      const selection = !this.groupedOption.value;

      let featuresToSave: Feature<Geometry>[] | undefined;
      if (selection && this.selectedFeature) {
        featuresToSave = [this.selectedFeature];
      }
      if (!selection) {
        featuresToSave = this.displayEditedFeatures;
      }

      if (featuresToSave) {
        this.getOriginalFeatures(featuresToSave).subscribe((originalFeatures) => {
          if (this.layerConfig && featuresToSave) {
            this.validationService
              .validEdition(featuresToSave, this.layer, originalFeatures, action === 'VALID', !selection)
              .subscribe({
                next: (message: string | boolean) => {
                  if (message && typeof message === 'string') {
                    this.notificationService.success(message);
                  }
                  if (featuresToSave) {
                    if (action === 'VALID') {
                      this.deleteOrphanLinkedEntities(featuresToSave);
                    } else {
                      this.deleteComments(featuresToSave);
                    }
                    if (this.notifyOption.value && this.notifyConfig[action]) {
                      this.notifyOfValidation(featuresToSave, action);
                    }
                  }
                },
                complete: () => (this.isSaving = false),
              });
          }
        });
        this.selectionService.selectedFeatures.reset();
        this.selectedFeature = undefined;
      }
    }
  }

  private deleteOrphanLinkedEntities(featuresSaved: Feature<Geometry>[]) {
    if (this.layerConfig) {
      const calls = [];
      for (const feature of featuresSaved) {
        const linkedId = feature.get('linked_entity');
        if (linkedId) {
          const url = GeoserverLayersUtils.wfsGetFeatureByPropertiesUrl(
            this.layerConfig,
            'linked_entity = ' + linkedId
          );
          if (url) {
            calls.push(this.validationService.getFeaturesFromGeoserver(url));
          }
        }
      }

      const ids = featuresSaved.map((feature) => feature.getId());
      forkJoin(calls).subscribe((entities) => {
        const linkedFeatures: Feature<Geometry>[] = [];
        if (entities.length) {
          for (const features of entities) {
            linkedFeatures.push(
              ...features
                .map((linkedFeature) => new GeoJSONFormat().readFeature(linkedFeature))
                .filter((olFeature) => !ids.includes(olFeature.getId()))
            );
          }
        }
        if (linkedFeatures.length) {
          this.validationService
            .deleteLinkedEntities(this.layer, ...linkedFeatures)
            .add(() => this.deleteComments(linkedFeatures));
        }
      });
    }
  }

  private notifyOfValidation(features: Feature<Geometry>[], action: ButtonType) {
    const ids = features.map(MapUtils.getFeatureId);
    const userModifEmails: Set<string> = new Set(features.map((feature) => feature.get('user_modif')));
    if (this.layerConfig?.validationConfiguration?.notify === 'VV_TRAP') {
      if (action === 'VALID') {
        this.notifyValidationService.notifyVVTrapCreation(ids);
      }
    } else if (this.layerConfig?.validationConfiguration?.notify === 'IS_PARCEL') {
      if (action === 'VALID') {
        const parcelData: ISNotification[] = features.map((feature) => {
          return {
            parcelId: feature.get('id_parcel'),
            userModifEmail: feature.get('user_modif'),
          };
        });
        this.notifyValidationService.notifyISParcelModification(parcelData);
      }
    } else if (this.layerConfig?.validationConfiguration?.notify === 'CNC_EDITION') {
      for (const user of userModifEmails) {
        const proposalIds: string[] = features
          .filter((f) => f.get('user_modif') == user)
          .map((f) => f.get('id_parcel'))
          .filter(GeneralUtils.isNotNull);
        if (proposalIds.length && user) {
          this.dialog
            .open(NotifyFeedbackDialogComponent, {
              disableClose: true,
              data: {
                userEmail: user,
                default:
                  action === 'VALID'
                    ? this.layerConfig.validationConfiguration.defaultValidationFeedback
                    : this.layerConfig.validationConfiguration.defaultRejectionFeedback,
              },
            })
            .afterClosed()
            .subscribe((notifContent: string) => {
              this.notifyValidationService.notifyQCCNCValidation(
                proposalIds,
                user,
                action === 'VALID',
                notifContent.split('\n')
              );
            });
        } else {
          this.notificationService.error(
            $localize`:Edition Validation|Error getting variables to notify:Des attributs de l'entité n'ont pas pu être récupéré. Veuillez prendre contact avec l'administrateur.`
          );
        }
      }
    }
  }

  private deleteComments(features: Feature<Geometry>[]) {
    if (this.layerConfig?.validationConfiguration?.comments) {
      const featureIds = features.map(MapUtils.getFeatureId);
      const dataLayerCode = this.layerConfig.geoserverLayerName;

      this.editionCommentService.deleteComments(featureIds, dataLayerCode).subscribe({
        next: (nb) => {
          if (nb) {
            this.notificationService.success(
              $localize`:Validation Edition|Delete comments success:Les commentaires associés ont été supprimés.`
            );
          }
        },
        error: () => {
          this.notificationService.error(
            $localize`:Validation Edition|Delete comments error:Une erreur est survenue lors de la suppression des commentaires associés. Veuillez contacter un administrateur.`
          );
        },
      });
    }
  }
}
