import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';
import { PageTitleStrategy } from '@core/configuration/page-title-strategy.config';
import { AdminGuard } from '@core/guards/admin.guard';
import { AuthGuard } from '@core/guards/auth.guard';
import { ClientAppLayoutComponent } from '@feature/client-app-layout/client-app-layout.component';
import { HomeComponent } from '@feature/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'applications/:id',
    component: ClientAppLayoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'administration/utilisateurs',
    loadComponent: () =>
      import('./feature/user-management/user-management.component').then((c) => c.UserManagementComponent),
    canMatch: [AuthGuard, AdminGuard],
    title: $localize`Gestion des utilisateurs`,
  },
  {
    path: 'administration/utilisateurs/:customerId',
    loadComponent: () =>
      import('./feature/user-management/user-management.component').then((c) => c.UserManagementComponent),
    canMatch: [AuthGuard, AdminGuard],
    title: $localize`Gestion des utilisateurs`,
  },
  {
    path: 'applications',
    loadComponent: () =>
      import('./feature/application-management/application-management.component').then(
        (c) => c.ApplicationManagementComponent
      ),
    canMatch: [AuthGuard],
    title: $localize`Mes applications`,
  },
  {
    path: 'administration/applications',
    loadComponent: () =>
      import('./feature/application-management/application-management.component').then(
        (c) => c.ApplicationManagementComponent
      ),
    canMatch: [AuthGuard],
    title: $localize`Gestion des applications`,
    data: { gestionMode: true },
  },
  {
    path: 'administration/applications/:userId',
    loadComponent: () =>
      import('./feature/application-management/application-management.component').then(
        (c) => c.ApplicationManagementComponent
      ),
    canMatch: [AuthGuard, AdminGuard],
    title: $localize`Gestion des applications`,
    data: { gestionMode: true },
  },
  {
    path: 'administration/clients',
    loadComponent: () =>
      import('./feature/customer-management/customer-management.component').then((c) => c.CustomerManagementComponent),
    canMatch: [AuthGuard, AdminGuard],
    title: $localize`Gestion des clients`,
  },
  {
    path: 'administration/clients/:groupId',
    loadComponent: () =>
      import('./feature/customer-management/customer-management.component').then((c) => c.CustomerManagementComponent),
    canMatch: [AuthGuard, AdminGuard],
    title: $localize`Gestion des clients`,
  },
  {
    path: 'administration/groupesClient',
    loadComponent: () =>
      import('./feature/group-management/customer-group-management.component').then(
        (c) => c.CustomerGroupManagementComponent
      ),
    canMatch: [AuthGuard, AdminGuard],
    title: $localize`Gestion des groupes client`,
  },
  {
    path: 'administration/synchronisation',
    loadComponent: () =>
      import('./feature/synchronisation-management/synchronisation-management.component').then(
        (c) => c.SynchronisationManagementComponent
      ),
    canMatch: [AuthGuard, AdminGuard],
    title: $localize`Gestion de la synchronisation`,
  },
  {
    path: 'mentions-legales/:id',
    loadComponent: () => import('./feature/legal-notices/legal-notices.component').then((c) => c.LegalNoticesComponent),
    title: $localize`Mentions légales`,
  },
  {
    path: 'politique-confidentialite',
    loadComponent: () =>
      import('./feature/privacy-policy/privacy-policy.component').then((c) => c.PrivacyPolicyComponent),
    title: $localize`Politique de confidentialité`,
  },
  {
    path: 'connexion',
    loadComponent: () => import('./feature/auth/login/login.component').then((c) => c.LoginComponent),
    title: $localize`Se connecter`,
  },
  {
    path: 'inscription',
    loadComponent: () =>
      import('./feature/auth/registration/registration.component').then((c) => c.RegistrationComponent),
    title: $localize`Créer un compte`,
  },
  {
    path: 'reinitialisation-mdp',
    loadComponent: () =>
      import('./feature/auth/forgotten-password/forgotten-password.component').then(
        (c) => c.ForgottenPasswordComponent
      ),
    title: $localize`Réinitialiser le mot de passe`,
  },
  {
    path: 'activation-compte',
    loadComponent: () =>
      import('./feature/auth/activate-account/activate-account.component').then((c) => c.ActivateAccountComponent),
    title: $localize`Activer le compte`,
  },
  {
    path: 'compte',
    loadComponent: () => import('./feature/user-account/user-account.component').then((c) => c.UserAccountComponent),
    title: $localize`Mon compte`,
  },
  {
    path: 'icare-report',
    loadComponent: () =>
      import('./feature/client-carto-app/widgets/icare/icare-report/icare-report.component').then(
        (c) => c.IcareReportComponent
      ),
    canMatch: [AuthGuard],
    title: $localize`:Icare report|Page title:Rapport Eudémis`,
  },
  {
    path: 'visiovol',
    loadComponent: () => import('./feature/visiovol/visiovol.component').then((c) => c.VisiovolComponent),
    title: $localize`Visiovol`,
  },
  {
    path: 'revolt',
    loadComponent: () =>
      import('./feature/revolt/revolt-subscription.component').then((c) => c.RevoltSubscriptionComponent),
    title: $localize`Revolt Timing Application`,
  },
  {
    path: 'unsubscribe',
    loadComponent: () =>
      import('./feature/notif-unsubscribe/notif-unsubscribe.component').then((c) => c.NotifUnsubscribeComponent),
    title: $localize`Mapview | Désinscription`,
  },
  {
    path: 'champ-propre',
    loadComponent: () =>
      import('./feature/champ-propre/champ-propre-subscription/champ-propre-subscription.component').then(
        (c) => c.ChampPropreComponent
      ),
    title: $localize`Champ Propre`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
  ],
})
export class AppRoutingModule {}
