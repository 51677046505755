import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { isNil } from 'lodash-es';
import moment from 'moment';

@Component({
  selector: 'smv-date-select',
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  templateUrl: './date-select.component.html',
  styleUrls: ['./date-select.component.scss'],
})
export class DateSelectComponent {
  @Input() appId!: number;
  @Input() limitToCampaign = true;
  public formattedDate?: string;
  public date: Date = new Date();
  public dateControl = new FormControl<Date>(new Date());
  public minDate?: Date;
  public maxDate?: Date;
  private DEFAULT_DATE_KEY = 'smv-select-default-date';

  constructor(
    private router: Router,
    private applicationService: ApplicationApiService
  ) {
    // Revenir à la dernière date choisie
    if (localStorage.getItem(this.DEFAULT_DATE_KEY)) {
      this.date = moment(localStorage.getItem(this.DEFAULT_DATE_KEY)).toDate();
      this.dateControl.setValue(this.date);
      this.setFormattedDate(this.date);
    }
    const appli = this.applicationService.currentApplication.getValue();

    if (this.limitToCampaign && appli) {
      this.minDate = moment(appli.functionnalityConfig.campaignStart, 'DD/MM').toDate();
      const campaignEndDate: Date = moment(appli.functionnalityConfig.campaignEnd, 'DD/MM').toDate();
      if (new Date() < campaignEndDate) {
        this.maxDate = new Date();
      } else {
        this.maxDate = campaignEndDate;
      }
    }
  }

  private setFormattedDate(date: Date) {
    this.formattedDate = moment(date).format('YYYY-MM-DD');
  }

  public OnDateChange(date?: Date | null) {
    if (!isNil(date)) {
      this.setFormattedDate(date);
      const triggerPdfButton = document.getElementById('triggerPdfButton');
      if (triggerPdfButton != undefined) {
        triggerPdfButton.scrollIntoView();
      }
    }
  }

  triggerPdf() {
    if (this.formattedDate) {
      const accessDate: Date = moment(this.formattedDate).toDate();
      // Sortir si la date indiquée dépasse les bornes
      if ((this.minDate && accessDate < this.minDate) || (this.maxDate && accessDate > this.maxDate)) {
        return;
      }
      localStorage.setItem(this.DEFAULT_DATE_KEY, this.formattedDate);
      this.router.navigate(['icare-report'], {
        state: {
          date: this.formattedDate,
          appId: this.appId,
        },
      });
    }
  }
}
