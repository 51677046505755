<smv-panel [title]="titleEditor">
  <mat-form-field appearance="fill">
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControl]="selectedEditors" multiple>
      <mat-checkbox
        color="accent"
        [checked]="allSelected()"
        [indeterminate]="someSelected()"
        (click)="updateAllSelected()"
        class="mat-mdc-option select-option unselectable"
      >
        <span class="label" i18n>Sélectionner tout</span>
      </mat-checkbox>
      <mat-divider />
      <mat-option *ngFor="let editor of editorsEmail" [value]="editor">{{ editor }}</mat-option>
    </mat-select>
  </mat-form-field>
</smv-panel>
<smv-panel [title]="titleDate" *ngIf="modifDates.length">
  <mat-slider min="0" [max]="modifDates.length - 1" step="1" showTickMarks class="slider">
    <input matSliderStartThumb [formControl]="dateStart" (dragEnd)="startThumbDragged()" />
    <input matSliderEndThumb [formControl]="dateEnd" (dragEnd)="endThumbDragged()" />
  </mat-slider>
  <div class="date-label">
    {{ dateStart.value | rangeDate: modifDates : dateEnd.value }}
  </div>
</smv-panel>
