import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';

import { environment } from '@env/environment';
import { map, Observable } from 'rxjs';

export const authInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const req_token = localStorage.getItem('Authorization');
  let modifiedReq;
  if (
    (req.url.startsWith(environment.apiUrl) || req.url.indexOf('services-webapp-syngentamap') >= 0) &&
    !req.headers.get('Authorization') &&
    req_token
  ) {
    modifiedReq = req.clone({
      headers: req.headers.set('Authorization', req_token),
    });
  }
  return next(modifiedReq || req).pipe(
    map((event: HttpEvent<unknown>) => {
      if (event instanceof HttpResponse) {
        const res_token = event.headers.get('Authorization');
        if (res_token) {
          localStorage.setItem('Authorization', res_token);
        }
      }
      return event;
    })
  );
};
