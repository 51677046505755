import { Component, computed, EventEmitter, input, Output } from '@angular/core';
import { Step, StepGroup } from './stepper.model';
import { NgClass } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'smv-stepper',
  standalone: true,
  imports: [MatIconModule, NgClass],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
})
export class StepperComponent {
  public currentStep = input('');
  public steps = input.required<Step[] | StepGroup[]>();

  public stepGroups = computed(() => this.toStepGroups(this.steps()));

  @Output() currentStepChange = new EventEmitter<string>();

  selectStep(step: Step): void {
    if (!step.disabled) {
      this.currentStepChange.emit(step.code);
    }
  }

  private toStepGroups(steps: Step[] | StepGroup[]): StepGroup[] {
    if (steps.length === 0) {
      return [];
    }
    const areGroups = (param: Step[] | StepGroup[]): param is StepGroup[] => Object.hasOwn(param[0], 'steps');
    return areGroups(steps) ? steps : [{ steps }];
  }
}
