<smv-sheet-statistic [widgetLabel]="title" [loading]="loading" [isDataLoaded]="isDataLoaded" [titleMulti]="titleMulti">
  @if (trapMulti.length) {
    <smv-icare-trap-statistics [multiple]="true" [traps]="trapMulti" statisticsMulti />
  }
  @if (traps.length) {
    <mat-accordion statisticsTrap>
      @for (trap of traps; track trap) {
        <mat-expansion-panel [expanded]="traps.length === 1">
          <mat-expansion-panel-header>
            <mat-panel-title class="title">
              @if (trap.trap_name) {
                {{ trap.trap_name }}
              } @else {
                {{ trap.trap_code }}
              }
            </mat-panel-title>
          </mat-expansion-panel-header>
          <smv-icare-trap-statistics [traps]="trapStatements[trap.trap_code]" />
        </mat-expansion-panel>
      }
    </mat-accordion>
  }
</smv-sheet-statistic>
