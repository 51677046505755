import { ApplicationModel } from './application-api/application.model';

export interface RightWrapper {
  Droit: Right;
}

export enum ProfileCode {
  LECTEUR = 'LECTEUR',
  SPECIFICATEUR = 'SPECIFICATEUR',
  EDITEUR = 'EDITEUR',
  GEST_DROITS = 'GEST_DROITS',
  VALID = 'VALID',
  ADMIN = 'ADMIN',
}

export interface Profile {
  id: number;
  code: ProfileCode;
  libelle: string;
  description: string;
}

export interface Right {
  id: number;
  application: ApplicationModel;
  email: string;
  profil: Profile;
  syncLocked: boolean;
}

export interface NewRight {
  application: ApplicationModel;
  email: string;
  profil: Profile;
}

export const profilesCanModify: ProfileCode[] = [
  ProfileCode.SPECIFICATEUR,
  ProfileCode.EDITEUR,
  ProfileCode.VALID,
  ProfileCode.ADMIN,
];
export const profilesCanEdit: ProfileCode[] = [ProfileCode.EDITEUR, ProfileCode.VALID, ProfileCode.ADMIN];
export const profilesCanValidate: ProfileCode[] = [ProfileCode.VALID, ProfileCode.ADMIN];
