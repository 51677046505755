<mat-expansion-panel expanded>
  <mat-expansion-panel-header i18n="OptiSemis|Filter">Cultures</mat-expansion-panel-header>
  <div class="smv-flex-col">
    @for (crop of crops; track crop.label) {
      <mat-checkbox color="accent" [(ngModel)]="crop.selected" (change)="computeFilter()">
        {{ crop.label }}
      </mat-checkbox>
    }
  </div>
</mat-expansion-panel>
