<mat-expansion-panel expanded>
  <mat-expansion-panel-header i18n>Graphiques</mat-expansion-panel-header>

  <div class="smv-statistic">
    <mat-slide-toggle [(ngModel)]="allTrapsDisplayed" (change)="onDisplayAllTrapsChanged()" i18n>
      Voir tous les pièges
    </mat-slide-toggle>
    <ng-container *ngIf="allTrapsDisplayed">
      <h4 class="title" i18n="Widget Statistic">Multi pièges</h4>
      <mat-radio-group
        [(ngModel)]="captureMode"
        (change)="resetDraw()"
        [disabled]="!allTrapsDisplayed"
        class="smv-capture-mode"
      >
        <mat-radio-button value="AUTOMATIC" i18n="Widget Statistic"> Automatique (zoom "visible") </mat-radio-button>
        <mat-radio-button value="MANUAL" i18n="Widget Statistic"> Saisie manuelle </mat-radio-button>
      </mat-radio-group>
      <mat-radio-group
        *ngIf="captureMode === 'MANUAL'"
        [(ngModel)]="drawingMode"
        (change)="resetDraw()"
        [disabled]="!allTrapsDisplayed"
        class="smv-drawing-mode"
      >
        <mat-radio-button value="POLYGONAL" i18n="Widget Statistic"> Tracé polygonal </mat-radio-button>
        <mat-radio-button value="MANUAL" i18n="Widget Statistic"> Tracé main libre </mat-radio-button>
      </mat-radio-group>
      <button mat-raised-button color="primary" [disabled]="!allTrapsDisplayed" (click)="onClickDrawingZone()">
        <div class="smv-stat-multi-trap">
          <ng-container *ngIf="captureMode === 'AUTOMATIC'">
            <mat-icon>show_chart</mat-icon>
            <span i18n="Widget Statistic">Afficher les informations</span>
          </ng-container>
          <ng-container *ngIf="captureMode === 'MANUAL'">
            <ng-container *ngIf="!isManualDrawing">
              <ng-container *ngIf="drawingMode === 'POLYGONAL'">
                <mat-icon>highlight_alt</mat-icon>
                <span i18n="Widget Statistic">Saisir un polygone</span>
              </ng-container>
              <ng-container *ngIf="drawingMode === 'MANUAL'">
                <mat-icon>gesture</mat-icon>
                <span i18n="Widget Statistic">Dessiner une zone</span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="isManualDrawing">
              <mat-icon *ngIf="drawingMode === 'POLYGONAL'">highlight_alt</mat-icon>
              <mat-icon *ngIf="drawingMode === 'MANUAL'">gesture</mat-icon>
              <span i18n="Widget Statistic">Arrêter la saisie</span>
            </ng-container>
          </ng-container>
        </div>
      </button>
      <h4 class="title" i18n="Widget Statistic">Historique piège</h4>
      <button mat-raised-button color="primary" [disabled]="!allTrapsDisplayed" (click)="resetMultiTrapsConfig()">
        <div class="smv-stat-trap">
          <mat-icon>insert_chart_outlined</mat-icon>
          <span i18n="Widget Statistic">Cliquez sur un piège</span>
        </div>
      </button>
    </ng-container>
  </div>
</mat-expansion-panel>
