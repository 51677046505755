import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CommonService, ListWrapper } from './common.service';
import { UserPosition } from '@core/model/user-position.model';
import { Farm } from '@core/model/farm.model';

export interface FarmWrapper {
  Farm: Farm;
}

@Injectable({ providedIn: 'root' })
export class ChampPropreService extends CommonService {
  protected override readonly baseUrl: string = this.baseUrl + '/champ-propre';

  protected pipeExtractFarm(obs: Observable<FarmWrapper>): Observable<Farm> {
    return obs.pipe(
      map((result: FarmWrapper) => {
        return result.Farm;
      })
    );
  }

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getPositions() {
    return this.pipeExtractList(this.http.get<ListWrapper<UserPosition>>(`${this.baseUrl}/positions`));
  }

  getFarm(userMail: string): Observable<Farm> {
    return this.pipeExtractFarm(this.http.get<FarmWrapper>(`${this.baseUrl}/farm/${userMail}`));
  }

  signUp(
    firstName: string,
    lastName: string,
    mail: string,
    organisation: string,
    position: string,
    phoneNumber: string,
    password: string
  ): Observable<string> {
    const body = {
      firstName: firstName,
      lastName: lastName,
      mail: mail,
      organisation: organisation,
      position: position,
      phoneNumber: phoneNumber,
      password: password,
    };
    return this.http.post(this.baseUrl + '/user-subscription/signup', body, { responseType: 'text' });
  }

  saveFarmInfo(farm: Farm, userMail: string): Observable<string> {
    const body = {
      name: farm.name,
      siret: farm.siret,
      address: farm.address,
      zipCode: farm.zipCode,
      city: farm.city,
      userMail: userMail,
    };
    return this.http.post(this.baseUrl + '/farm/save', body, { responseType: 'text' });
  }
}
