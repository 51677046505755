import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface IframeDialogConfig {
  title: string;
  contentUrl: string;
}

@Component({
  selector: 'smv-iframe-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatDividerModule],
  templateUrl: './iframe-dialog.component.html',
  styleUrls: ['./iframe-dialog.component.scss'],
})
export class IframeDialogComponent {
  public url: SafeResourceUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public config: IframeDialogConfig,
    private readonly sanitizer: DomSanitizer
  ) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(config.contentUrl);
  }
}
