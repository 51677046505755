import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { WfsLayerConfig } from '@core/model/layer-config.model';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { GeoserverLayersUtils } from '@core/utils/geoserver-layers.utils';
import { GeoserverVariablesService } from '@feature/client-carto-app/widgets/widgets-filter-cql.service';
import { Subscription } from 'rxjs';
import { EditionValidationService } from '../edition-validation.service';

@Component({
  selector: 'smv-validation-filter-data',
  templateUrl: './validation-filter-data.component.html',
  styleUrls: ['./validation-filter-data.component.scss'],
})
export class ValidationFilterDataComponent implements OnInit, OnDestroy {
  @Input() layer!: WfsLayerConfig;

  public titleEditor = $localize`Filtre éditeurs`;
  public titleDate = $localize`Filtre date de modification`;
  public label = $localize`Editeurs`;

  public editorsEmail: string[] = [];
  public selectedEditors = new FormControl<string[]>([]);
  public modifDates: string[] = [];
  public dateStart = new FormControl<number>(0, { nonNullable: true });
  public dateEnd = new FormControl<number>(0, { nonNullable: true });

  private subscriptions = new Subscription();

  constructor(
    private applicationService: ApplicationApiService,
    private validationService: EditionValidationService,
    private geoserverVariables: GeoserverVariablesService
  ) {}

  public ngOnInit(): void {
    this.selectedEditors.valueChanges.subscribe(() => this.onEditorsSelectionChange());
    new FormArray([this.dateStart, this.dateEnd]).valueChanges.subscribe(() => this.onDateSelectionChange());

    this.subscriptions.add(this.validationService.resetValidationFilters.subscribe(() => this.getDataFromGeoserver()));

    this.getDataFromGeoserver(true);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getDataFromGeoserver(init = false) {
    const appType = this.applicationService.currentApplication.getValue()?.functionnalityConfig.code;
    const url = GeoserverLayersUtils.wfsGetFeaturePropertyUrl(
      this.layer,
      'user_modif,date_modif',
      `user_modif is not null AND is_modified = true AND modified_in = '${appType}'`,
      'date_modif A'
    );
    if (url) {
      this.validationService.getPropertiesFromGeoserver(url, 'user_modif', 'date_modif').subscribe((properties) => {
        this.editorsEmail = [];
        this.modifDates = [];
        this.editorsEmail.push(...properties['user_modif']);
        this.modifDates.push(...properties['date_modif']);

        if (init) {
          this.selectedEditors.setValue(properties['user_modif']);
          this.dateEnd.setValue(this.modifDates.length - 1);
        } else {
          if (this.modifDates.length >= this.dateEnd.value) {
            this.dateEnd.setValue(this.modifDates.length - 1);
          }
          if (this.modifDates.length >= this.dateStart.value) {
            this.dateStart.setValue(0);
          }
        }
      });
    }
  }

  allSelected(): boolean {
    return this.selectedEditors.value?.length === this.editorsEmail.length;
  }

  someSelected(): boolean {
    const value = this.selectedEditors.value?.length ?? -1;
    return value > 0 && value < this.editorsEmail.length;
  }

  updateAllSelected() {
    this.selectedEditors.setValue(this.allSelected() ? [] : this.editorsEmail);
  }

  private onEditorsSelectionChange() {
    this.geoserverVariables.editionValidationState.setParticularValue('editors', this.selectedEditors.value ?? []);
  }

  private onDateSelectionChange() {
    const range: string[] = [];
    if (this.modifDates.length) {
      range.push(this.modifDates[this.dateStart.value], this.modifDates[this.dateEnd.value]);
    }
    this.geoserverVariables.editionValidationState.setParticularValue('dateRange', range);
  }

  // Hack for https://github.com/angular/components/issues/26250
  public startThumbDragged() {
    this.dateStart.setValue(this.dateStart.value);
  }

  // Hack for https://github.com/angular/components/issues/26250
  public endThumbDragged() {
    this.dateEnd.setValue(this.dateEnd.value);
  }
}
