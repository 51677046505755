<mat-expansion-panel>
  <mat-expansion-panel-header>{{ titleCulture }}</mat-expansion-panel-header>
  <smv-vigie-virose-culture
    [cultures]="cultures"
    (selectionChanged)="onCultureSelectionChanged()"
    class="vv-obs-cultures"
  />
</mat-expansion-panel>

@if (indicators.insects.length || indicators.viruses.length) {
  <mat-expansion-panel>
    <mat-expansion-panel-header>{{ titleIndicators }}</mat-expansion-panel-header>
    <smv-vigie-virose-indicators
      [indicators]="indicators"
      (selectionChanged)="onSelectionChanged($event)"
      class="vv-obs-indicators"
    />
  </mat-expansion-panel>
}
