<div>
  <div class="overlay"></div>
  @if (!loading) {
    <mat-card class="smv-sheet">
      <mat-card-title>
        <div class="header">
          <div>
            @if (widgetLabel) {
              <span>{{ widgetLabel }} - </span>
            }
            <span i18n="Sheet Statistic">Statistiques</span>
          </div>
          <button mat-icon-button (click)="ngOnDestroy()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content class="content">
        @if (isDataLoaded) {
          @if (isMulti) {
            <mat-expansion-panel class="smv-expansion-parent-panel-flat" expanded>
              <mat-expansion-panel-header>{{ titleMulti }}</mat-expansion-panel-header>
              <ng-content select="[statisticsMulti]" />
            </mat-expansion-panel>
          } @else {
            <ng-container>
              <mat-accordion>
                <ng-content select="[statisticsTrap]" />
              </mat-accordion>
            </ng-container>
          }
        } @else {
          <div class="noStatisticsData" i18n="No data">Aucune donnée à afficher.</div>
        }
      </mat-card-content>
    </mat-card>
  }
</div>
