import { Component } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Indexable } from '@core/utils/general.utils';
import { GeoserverVariablesService } from '@feature/client-carto-app/widgets/widgets-filter-cql.service';

interface Generation {
  label: string;
  value: number;
}

@Component({
  standalone: true,
  selector: 'smv-icare-filter-generation',
  imports: [MatCheckboxModule],
  templateUrl: './icare-filter-generation.component.html',
  styleUrls: ['./icare-filter-generation.component.scss'],
})
export class IcareFilterGenerationsComponent {
  public generations: Generation[] = [
    {
      label: $localize`:Filter Label|Flight generation:Génération 1`,
      value: 1,
    },
    {
      label: $localize`:Filter Label|Flight generation:Génération 2`,
      value: 2,
    },
    {
      label: $localize`:Filter Label|Flight generation:Génération 3`,
      value: 4,
    },
  ];

  private filter: Indexable<string> = {
    0: 'flight_realizationg1 <= 5',
    1: 'flight_realizationg1 > 5 AND flight_realizationg2 <= 5',
    2: 'flight_realizationg1 > 95 AND flight_realizationg3 <= 5',
    3: 'flight_realizationg1 > 5 AND flight_realizationg3 <= 5',
    4: 'flight_realizationg2 > 95',
    5: '((flight_realizationg1 > 5 AND flight_realizationg2 <= 5) OR flight_realizationg2 > 95)',
    6: 'flight_realizationg1 > 95',
    7: 'flight_realizationg1 > 5',
  };

  private selection = 7;

  constructor(private geoserverVariables: GeoserverVariablesService) {
    this.geoserverVariables.icareGenerationState.setValue(this.filter[this.selection]);
  }

  public selectionChange(state: boolean, value: number) {
    this.selection = this.selection + (state ? value : -value);
    this.geoserverVariables.icareGenerationState.setValue(this.filter[this.selection]);
  }
}
