@for (stepGroup of stepGroups(); track stepGroup) {
  <div class="step-group">
    <div class="group-title">{{ stepGroup.groupName }}</div>
    @for (step of stepGroup.steps; track step.code) {
      <div
        class="step"
        [ngClass]="{ current: step.code === currentStep(), completed: step.completed, disabled: step.disabled }"
        (click)="selectStep(step)"
      >
        <div class="step-circle">
          @if ((step.code === currentStep() || !step.completed) && step.icon) {
            <mat-icon>{{ step.icon }}</mat-icon>
          } @else if (step.completed) {
            <mat-icon>check</mat-icon>
          }
        </div>
        {{ step.text }}
      </div>
      <div class="step-delimiter"></div>
    }
  </div>
}
