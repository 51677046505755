<smv-filter-type-data
  [(filter)]="filter"
  [authorizedSimulation]="authorizedSimulation"
  (filterChange)="onFilterChange()"
>
  @if (filter === 'FILTER_OBSERVATION') {
    <smv-icare-filter-observations samples />
  } @else if (filter === 'FILTER_SIMULATION') {
    <smv-icare-filter-simulations simulations />
  }
</smv-filter-type-data>
