import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { FormFieldWrapperComponent } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { Farm } from '@core/model/farm.model';
import { AuthService } from '@core/services/auth.service';
import { ChampPropreService } from '@core/services/champ-propre.service';
import { NotificationService } from '@core/services/notification.service';
import { TemplateUtils } from '@core/utils/template.utils';
import { Subscription } from 'rxjs';

interface RegistrationFormGroup {
  name: FormControl<string | null>;
  siret: FormControl<string | null>;
  address: FormControl<string | null>;
  zipCode: FormControl<string | null>;
  city: FormControl<string | null>;
}

const modules = [
  CommonModule,
  FormFieldWrapperComponent,
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  ReactiveFormsModule,
  MatInputModule,
  MatSelectModule,
  MatIcon,
];

@Component({
  selector: 'smv-champ-propre-farm-info',
  standalone: true,
  imports: modules,
  templateUrl: './farm-info.component.html',
  styleUrl: './farm-info.component.scss',
})
export class FarmInfoComponent implements OnDestroy {
  public userMail = this.authService.getUserEmailInSync() || '';
  public farm: Farm | null = null;

  public form = new FormGroup<RegistrationFormGroup>({
    name: new FormControl<string>(''),
    siret: new FormControl<string>('', { validators: [Validators.pattern(/^\d{14}$/)] }),
    address: new FormControl<string>(''),
    zipCode: new FormControl<string>(''),
    city: new FormControl<string>(''),
  });

  constructor(
    private readonly champPropreService: ChampPropreService,
    private readonly notificationService: NotificationService,
    private readonly authService: AuthService
  ) {
    this.champPropreService.getFarm(this.userMail).subscribe({
      next: (farm) => {
        this.farm = farm;
        this.form.patchValue(farm);
      },
    });
  }

  ngOnDestroy(): void {
    TemplateUtils.resetHeaderFooter();
  }

  save(): void {
    const farm = this.form.getRawValue();
    if (this.userMail == '') {
      this.notificationService.error($localize`Vous n'êtes pas connecté.`);
      return;
    }
    this.champPropreService.saveFarmInfo(farm, this.userMail).subscribe({
      next: () => {
        this.notificationService.success($localize`Enregistré avec succes.`);
      },
      error: () => {
        this.notificationService.error($localize`Erreur lors de l'enregistrement.`);
      },
    });
  }
}
